import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// for passing in lng and translations on init

// other lang
const Languages = ['en', 'nl'];
const resources = {
    en: {
        translation: {
            "sidenav": {
                "Dashboard": "Dashboard",
                "dashboard": "dashboard",
                "Installers": "Installers",
                "installers": "installers",
                "Pricing": "Pricing",
                "pricing": "pricing",
                "Overview": "Overview",
                "overview": "overview",
                "Customers": "Customers",
                "customers": "customers",
                "profile": "profile",
                "password": "password",
                "Contact": "Contact",
                "contact": "contact"
            },
            "action": {
                "Add": "Add",
                "Cancel": "Cancel",
                "Search": "Search",
                "Reset": "Reset",
                "Modify": "Modify",
                "Edit": "Edit",
                "Browse": "Browse",
                "Upload": "Upload"
            },
            "customAction": {
                "Create New Customer": "Create New Customer",
                "Create New Installer": "Create New Installer",
                "Scan QR Code": "Scan QR Code"
            },
            "general": {
                "Dashboard": "Dashboard",
                "Installers": "Installers",
                "Charging Stations": "Charging Stations",
                "Overview": "Overview",
                "Customers": "Customers",
                "Create Installer": "Create Installer",
                "Edit Installer": "Edit Installer",
                "Create Customer": "Create Customer",
                "Edit Customer": "Edit Customer",
                "Active": "Active",
                "In Active": "In Active",
                "Select": "Select",
                "My Profile": "My Profile",
                "Profile Information": "Profile Information",
                "Change Password": "Change Password",
                "Logout": "Logout",
                "Customer Name": "Customer Name",
                "Installer Name": "Installer Name",
                "Session": "Session",
                "Price": "Price",
                "Start Time": "Start Time",
                "End Time": "End Time",
                "Total Duration": "Total Duration",
                "Used Up": "Used Up",
                "First Name": "First Name",
                "Last Name": "Last Name",
                "Email": "Email",
                "Action": "Action",
                "Amount End User": "Amount End User",
                "Margin": "Margin",
                "Selling": "Selling",
                "Optimile Device Id": "Optimile Device Id",
                "Roaming Cost": "Roaming Cost",
                "Year": "Year",
                "Month": "Month",
                "Consumed": "Consumed",
                "Total Turnover": "Total Turnover",
                "Sales": "Sales",
                "Revenue Host": "Revenue Host",
                "Approved On": "Approved On",
                "Showing": "Showing",
                "to": "to",
                "of": "of",
                "entries": "entries",
                "Hours": "Hours",
                "minutes": "minutes",
                "User": "User",
                "Information": "Information",
                "Address": "Address",
                "Postal Code": "Postal Code",
                "City": "City",
                "Serial Number": "Serial Number",
                "Charging Station Name": "Charging Station Name",
                "Basic Details": "Basic Details",
                "Power Connection": "Power Connection",
                "Cable Thickness": "Cable Thickness",
                "Connection Value Installation Machine": "Connection Value Installation Machine",
                "Connection Value": "Connection Value",
                "Public": "Public",
                "Home Charger": "Home Charger",
                "Photos": "Photos",
                "installation": "installation",
                "Photo installation circuit breaker": "Photo installation circuit breaker",
                "earth leakage circuit breaker": "earth leakage circuit breaker",
                "Overview photo group cabinet": "Overview photo group cabinet",
                "Photo of the cable connected to the charging unit": "Photo of the cable connected to the charging unit",
                "Front view loading unit": "Front view loading unit",
                "Label Charging": "Label Charging",
                "Select Installer": "Select Installer",
                "Station Name": "Station Name",
                "Current Password": "Current Password",
                "New Password": "New Password",
                "Confirm Password": "Confirm Password",
                "Last Updated On": "Last Updated On",
                "User Name": "User Name",
                "Upload Profile": "Upload Profile",
                "See our dashboard options": "See our dashboard options",
                "Sidenav Colors": "Sidenav Colors",
                "Choose between different sidenav types": "Choose between different sidenav types",
                "Dark": "Dark",
                "Transparent": "Transparent",
                "White": "White",
                "Navbar Fixed": "Navbar Fixed",
                "Light": "Light",
                "Password": "Password",
                "Installer": "Installer"
            },
            "validation": {
                "Installer is required": "Installer is required",
                "First Name is required": "First Name is required",
                "Last Name is required": "Last Name is required",
                "Email is required": "Email is required",
                "Address is required": "Address is required",
                "Postal Code is required": "Postal Code is required",
                "City is required": "City is required",
                "Serial Number is required": "Serial Number is required",
                "Circuit Breaker is required": "Circuit Breaker is required",
                "Group Box is required": "Group Box is required",
                "Cable Charging is required": "Cable Charging is required",
                "Front Charging is required": "Front Charging is required",
                "Label Charging is required": "Label Charging is required",
                "Station Name is required": "Station Name is required",
                "Cable Thickness is required": "Cable Thickness is required",
                "Connection Value is required": "Connection Value is required",
                "Public is required": "Public is required",
                "Home Charger is required": "Home Charger is required",
                "Power Connection is required": "Power Connection is required",
                "Current Password is required": "Current Password is required",
                "New Password is required": "New Password is required",
                "Both password need to be the same": "Both password need to be the same",
                "Password is required": "Password is required"
            },
            "roles": {
                "Admin": "Admin",
                "Installer": "Installer",
                "Customer": "Customer"
            }
        }
    },
    nl: {
        translation: {
            "sidenav": {
                "Dashboard": "Dashboard",
                "dashboard": "dashboard",
                "Installers": "Installateurs",
                "installers": "installateurs",
                "Pricing": "Prijzen",
                "pricing": "prijzen",
                "Overview": "Overzicht",
                "overview": "overzicht",
                "Customers": "Klanten",
                "customers": "klanten",
                "profile": "Profiel",
                "password": "wachtwoord",
                "Contact": "Contact",
                "contact": "contact"
            },
            "action": {
                "Add": "Toevoegen",
                "Cancel": "Annuleren",
                "Search": "Zoeken",
                "Reset": "Resetten",
                "Modify": "Aanpassen",
                "Edit": "Bewerk",
                "Browse": "Bladeren op",
                "Upload": "Uploaden"
            },
            "customAction": {
                "Create New Customer": "Nieuwe klant aanmaken",
                "Create New Installer": "Nieuw installatieprogramma maken",
                "Scan QR Code": "Scan QR-code"
            },
            "general": {
                "Dashboard": "Dashboard",
                "Installers": "Installateurs",
                "Charging Stations": "Oplaadstations",
                "Overview": "Overzicht",
                "Customers": "Klanten",
                "Create Installer": "Installatieprogramma maken",
                "Edit Installer": "Installatieprogramma bewerken",
                "Create Customer": "Klant aanmaken",
                "Edit Customer": "Klant bewerken",
                "Active": "actief",
                "In Active": "inactief",
                "Select": "Selecteer",
                "My Profile": "Mijn profiel",
                "Profile Information": "profiel informatie",
                "Change Password": "Wachtwoord wijzigen",
                "Logout": "uitloggen",
                "Customer Name": "klantnaam",
                "Installer Name": "Naam installateur",
                "Session": "Sessie",
                "Price": "Prijs",
                "Start Time": "Starttijd",
                "End Time": "Eindtijd",
                "Total Duration": "Totale duur",
                "Used Up": "Verbruikt",
                "First Name": "Voornaam",
                "Last Name": "Achternaam",
                "Email": "E-mail",
                "Action": "Actie",
                "Amount End User": "ABedrag Eindgebruiker",
                "Margin": "Marge",
                "Selling": "Verkoop",
                "Optimile Device Id": "Optimile-apparaat-ID",
                "Roaming Cost": "Roaming kosten",
                "Year": "Jaar",
                "Month": "Maand",
                "Consumed": "verbruikt",
                "Total Turnover": "Totale omzet",
                "Sales": "verkoop",
                "Revenue Host": "Inkomsten gastheer",
                "Approved On": "Goedgekeurd op",
                "Showing": "toont",
                "to": "tot",
                "of": "van",
                "entries": "inzendingen",
                "Hours": "Uur",
                "minutes": "minuten",
                "User": "Gebruiker",
                "Information": "Informatie",
                "Address": "Adres",
                "Postal Code": "Postcode",
                "City": "Stad",
                "Serial Number": "Serienummer",
                "Charging Station Name": "Naam laadstation",
                "Basic Details": "Basisgegevens",
                "Power Connection": "Stroomaansluiting",
                "Cable Thickness": "Kabel Dikte",
                "Connection Value Installation Machine": "Aansluitwaarde Installatie Machine",
                "Connection Value": "Verbindingswaarde",
                "Public": "Openbaar",
                "Home Charger": "Oplader voor thuis",
                "Photos": "Foto",
                "installation": "installatie",
                "Photo installation circuit breaker": "Foto installatie stroomonderbreker",
                "earth leakage circuit breaker": "aardlekschakelaar",
                "Overview photo group cabinet": "Overzicht fotogroepenkast",
                "Photo of the cable connected to the charging unit": "Foto van de kabel die is aangesloten op de oplader",
                "Front view loading unit": "Vooraanzicht laadeenheid",
                "Label Charging": "Label opladen",
                "Select Installer": "Selecteer Installateur",
                "Station Name": "station naam",
                "Current Password": "huidig ​​wachtwoord",
                "New Password": "nieuw paswoord",
                "Confirm Password": "bevestig wachtwoord",
                "Last Updated On": "Laatst geupdate op",
                "User Name": "Gebruikersnaam",
                "Upload Profile": "Profiel uploaden",
                "See our dashboard options": "Bekijk onze dashboardopties",
                "Sidenav Colors": "Sidenav-kleuren",
                "Choose between different sidenav types": "Kies uit verschillende sidenav-types",
                "Dark": "Donker",
                "Transparent": "Transparant",
                "White": "Wit",
                "Navbar Fixed": "Navigatiebalk opgelost",
                "Light": "Licht",
                "Password": "Wachtwoord",
                "Installer": "Installateur"
            },
            "validation": {
                "Installer is required": "Installateur is vereist",
                "First Name is required": "Voornaam is verplicht",
                "Last Name is required": "Achternaam is verplicht",
                "Email is required": "E-mail is vereist",
                "Address is required": "adres is vereist",
                "Postal Code is required": "Postcode is vereist",
                "City is required": "Stad is required",
                "Serial Number is required": "Serienummer is vereist",
                "Circuit Breaker is required": "Zekering is vereist",
                "Group Box is required": "Groepsbox is vereist",
                "Cable Charging is required": "Kabel opladen is vereist",
                "Front Charging is required": "Voor opladen is vereist",
                "Label Charging is required": "Label opladen is vereist",
                "Station Name is required": "station naam is vereist",
                "Cable Thickness is required": "Kabel Dikte is vereist",
                "Connection Value is required": "Verbindingswaarde is vereist",
                "Public is required": "Openbaar is vereist",
                "Home Charger is required": "Oplader voor thuis is vereist",
                "Power Connection is required": "Stroomaansluiting is vereist",
                "Current Password is required": "huidig ​​wachtwoord is vereist",
                "New Password is required": "nieuw ​​wachtwoord is vereist",
                "Both password need to be the same": "Beide wachtwoorden moeten hetzelfde zijn",
                "Password is required": "Wachtwoord is vereist"
            },
            "roles": {
                "Admin": "Beheerder",
                "Installer": "Installateur",
                "Customer": "Klant"
            }
        }
    }
};
i18n.use(Backend)
    // detect user language
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    .init({
        fallbackLng: 'en',
        debug: true,
        whitelist: Languages,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        lng: "en",
        resources
    });


export default i18n;