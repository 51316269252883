import {
    PRICESETTINGDATA
} from "../actions/types";

const initialState = {
    paramsData: null,
    data: null
};
function priceSettingReducer(priceSettingsData = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case PRICESETTINGDATA:
            // add session data in state
            return { ...priceSettingsData, data: payload?.priceSettingResponse, paramsData: { ...priceSettingsData?.paramsData?.params, ...payload.params } };
        default:
            return priceSettingsData;
    }
}

export default priceSettingReducer;  