export function getAllowedRoutes(routes) {
    const role = JSON.parse(localStorage.getItem('roles'));
    let allow = [];
    return routes.filter(checkRole);
    function checkRole(routes) {
        if (routes?.permission?.includes(role)) {
            allow.push(routes);
            return allow;
        }
    }
}