import {
    INSTALLERSDATA
} from "../actions/types";

const initialState = {
    data: null,
    paramsData: null,
};
function installerReducer(installersData = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case INSTALLERSDATA:
            // add installers data in state
            return { ...installersData, data: payload?.installersResponse, paramsData: { ...installersData?.paramsData?.params, ...payload.params } };
        default:
            return installersData;
    }
}

export default installerReducer;