import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import auth from './reducers/auth';
import sessions from './reducers/sessions';
import customers from './reducers/customer';
import installers from './reducers/installers';
import priceSetting from './reducers/priceSetting';
import overview from './reducers/overview';

/* Converting an object whose values are different reducing functions into a single reducing function using combineReducers helper function. */
const reducer = combineReducers({
    // here we will be adding reducers
    auth,
    sessions,
    customers,
    installers,
    priceSetting,
    overview
});
// Passing combine reducers to configureStore
const store = configureStore({
    reducer,
})
export default store;