
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "commonComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commonComponents/Navbars/DashboardNavbar";
// import Footer from "commonComponents/Footer";
import DataTable from "commonComponents/Tables/DataTable";

// Data
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@mui/material";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { fetchCustomer } from "redux/actions/customer";
import { useMemo, useState } from "react";
import Filter from "./filter";
import { fetchInstallers } from "redux/actions/installers";
import { fetchCustomerList } from "redux/actions/customer";
import { useTranslation } from 'react-i18next';
import Helpers from "helpers";

function Customers() {
    const Navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false);
    const list = useSelector(state => state.customers.data);
    let columns = [];
    let customerListRow = [];
    useMemo(() => {
        const fetch = async () => {
            setLoader(true);
            await dispatch(fetchCustomer());
            await dispatch(fetchCustomerList());
            await dispatch(fetchInstallers());
            setLoader(false);
        }
        fetch();
    }, []);
    const handleCustomerEdit = (id) => {
        // get edit installer data
        Navigate(`/edit/customers/${id}`);
    };
    if (list?.results) {
        columns = [
            { Header: t("general.First Name"), accessor: "fname", align: "left" },
            { Header: t("general.Last Name"), accessor: "lname", align: "left" },
            { Header: t("general.Email"), accessor: "email", align: "left" },
            { Header: t("general.Approved On"), accessor: "approvedOn", align: "left" },
            { Header: t("general.Action"), accessor: "action", align: "center" },
            { Header: "Download PDF", accessor: "downloadPdf", align: "center" },
        ]
        /* 
        * Set rows of project data array for dataTable list.
        * Returns rows, columns, project id and modal states
        */
        let rowObj = {};
        for (let data of list?.results) {
            rowObj['fname'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data.UserToCustomer.firstName}
            </MDTypography>;
            rowObj['lname'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data.UserToCustomer.lastName ? data.UserToCustomer.lastName : '-'}
            </MDTypography>;
            rowObj['email'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data.email ? data.email : '-'}
            </MDTypography>
            rowObj['approvedOn'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data.UserToCustomer.installationReportApprovedOn ? Helpers.formatDate(data.UserToCustomer.installationReportApprovedOn) : '-'}
            </MDTypography>
            rowObj['downloadPdf'] = (
                <> <MDTypography component="a" href={data?.UserToCustomer?.installationReportUrl} download target="_blank" variant="button" color="text" fontWeight="medium">
                    <Icon>download</Icon>
                </MDTypography></>)
            rowObj['action'] = (<>
                <MDTypography type="button" variant="body" color="info" onClick={() => handleCustomerEdit(data.userId)}>
                    <Icon>edit</Icon>
                </MDTypography>
            </>)
            customerListRow.push(rowObj);
            rowObj = {};
        }
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Filter />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={0.5}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <MDTypography style={{ marginTop: "5px", fontSize: "17px" }} variant="h6" color="white">
                                            {t('general.Customers')}
                                        </MDTypography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div style={{ float: 'right' }} >
                                            <MDButton variant="outlined" color="light" onClick={() => Navigate('/create/customers')}>{t('customAction.Create New Customer')}</MDButton>
                                        </div>
                                    </Grid>
                                </Grid>

                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns: columns, rows: customerListRow }}
                                    isSorted={false}
                                    entriesPerPage={false}
                                    showTotalEntries={true}
                                    noEndBorder
                                    loader={loader}
                                    pagination={{ count: list?.totalPages ? list?.totalPages : 5, fetchtype: 'fetchCustomer' }}
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            {/* <Footer /> */}
        </DashboardLayout>
    );
}

export default Customers;
