import {
    OVERVIEWDATA
} from "../actions/types";
import axios from "axios";
import Service from "service";
import Helpers from "helpers";

// Actions
export const fetchOverview = (page, filter, csv) => async dispatch => {
    try {
        let params = {
            type: 'Overview',
        };
        const loggedRole = JSON.parse(localStorage.getItem('roles'));
        const id = JSON.parse(localStorage.getItem('loggedUserData'));
        // Check role of logged data
        if (loggedRole === 'installer') {
            params['installerId'] = id.user.userId;
        }
        if (page) {
            params['page'] = page;
        }
        if (filter?.installerId) {
            params['installerId'] = filter.installerId;
        }
        if (csv) {
            params['csv'] = true;
        }
        // Calling Session API
        const res = await axios.get(`${process.env.REACT_APP_BaseUrl}/api/v1/sessions/get-sessions-overview`, Service.config(params));
        if (res.data && csv) {
            Helpers.csvDownload(res.data, res.headers);
            return;
        }
        if (res.data) {
            dispatch({
                type: OVERVIEWDATA,
                payload: {
                    overviewResponse: res.data.data,
                    params: params
                }
            });
            return Promise.resolve(res.data.data);
        }
    } catch (e) {
        return Promise.reject(e);
    }
}