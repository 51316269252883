import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { useFormik } from 'formik';
import { fetchCustomer } from 'redux/actions/customer';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import Select from "react-select";
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import breakpoints from 'assets/theme/base/breakpoints';
import { Grid } from '@mui/material';

export default function Filter() {
    const [mobileView, setMobileView] = useState(false);
    const { t } = useTranslation();
    const InstallerList = useSelector(state => state?.installers?.data);
    const allCustomer = useSelector(state => state.customers.list);
    const dispatch = useDispatch();
    const [selectedOptions, setSelectedOptions] = useState();
    const [selectedCustomerOptions, setSelectedCustomerOptions] = useState();
    const loggedRole = JSON.parse(localStorage.getItem('roles'));
    const formik = useFormik({
        initialValues: {
            installerId: ""
        },
        onSubmit: async (values) => {
            if (selectedOptions?.value) {
                formik.values.installerId = selectedOptions.value;
                // await dispatch(fetchCustomer(null, { installerId: selectedOptions?.value }));
            }
            if (selectedCustomerOptions?.value) {
                formik.values.customerId = selectedCustomerOptions.value;
            }
            if (selectedOptions?.value || selectedCustomerOptions?.value) {
                await dispatch(fetchCustomer(null, { installerId: selectedOptions?.value, customerId: selectedCustomerOptions?.value }));
            }
        }
    });
    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: "transparent",
            fontSize: 17,
            borderRadius: state.isFocused ? "3px 3px 0 0" : 7,
            boxShadow: state.isFocused ? null : null,
        })
    };
    let optionList = [];
    if (InstallerList?.results) {
        for (let data of InstallerList?.results) {
            if (data.userId) {
                optionList.push(
                    { value: data.userId, label: data?.Installer?.firstName + ' ' + data?.Installer?.lastName }
                )
            }
        }
    }
    let optionCustomerList = [];
    if (allCustomer?.results) {
        for (let data of allCustomer?.results) {
            if (data.UserToCustomer.customerId) {
                optionCustomerList.push(
                    { value: data.userId, label: data.UserToCustomer.firstName + ' ' + data.UserToCustomer.lastName }
                )
            }
        }
    }
    const handleSelect = (data) => {
        setSelectedOptions(data);
    }
    const handleCustomerSelect = (data) => {
        setSelectedCustomerOptions(data);
    }
    const resetData = async () => {
        setSelectedOptions('');
        setSelectedCustomerOptions('');
        await dispatch(fetchCustomer(null, null));
    }
    useEffect(() => {
        // A function that sets the display state for the DefaultFormMobile.
        function displayMobileView() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true);
            } else {
                setMobileView(false);
            }
        }
        /** 
         The event listener that's calling the displayMobileView function when 
         resizing the window.
        */
        window.addEventListener("resize", displayMobileView);

        // Call the displayMobileView function to set the state with the initial value.
        displayMobileView();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", displayMobileView);
    }, []);
    return (
        <Grid>
            <MDBox component="form" role="form" m={1}
                display="flex"
                justifyContent="flex-end"
                onSubmit={formik.handleSubmit}
            >
                <Grid>
                    {loggedRole !== 'installer' && <FormControl style={{
                        'width': '300px',
                    }}>
                        <Select
                            styles={customStyles}
                            options={optionList}
                            placeholder={t('general.Select') + ' ' + t('general.Installer Name')}
                            value={selectedOptions}
                            onChange={handleSelect}
                            isSearchable={true}
                            autoWidth={false}
                        />
                        {mobileView && <br />}
                    </FormControl>}{!mobileView && ' '}
                    <FormControl style={{
                        'width': '300px',
                    }}>
                        <Select
                            styles={customStyles}
                            options={optionCustomerList}
                            placeholder={t('general.Select') + ' ' + t('general.Customer Name')}
                            value={selectedCustomerOptions}
                            onChange={handleCustomerSelect}
                            isSearchable={true}
                            autoWidth={false}
                        />
                        {mobileView ? <br /> : ' '}
                    </FormControl>&nbsp;
                    <MDButton variant="gradient" color="info" type="submit">
                        {t('action.Search')}
                    </MDButton>&nbsp;
                    <MDButton variant="gradient" type="reset" color="primary" onClick={resetData}>
                        {t('action.Reset')}
                    </MDButton>
                </Grid>
            </MDBox>
        </Grid>
    );
}