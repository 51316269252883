
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/background.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotPassword } from "redux/actions/auth";
import { toast } from "react-toastify";
import { Alert } from "@mui/material";

function Cover() {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      Reset: {
        email: ""
      }
    },
    validationSchema: Yup.object({
      Reset: Yup.object({
        email: Yup.string().required("Email is required")
      })
    }),
    onSubmit: async (values) => {
      if (values?.Reset) {
        dispatch(forgotPassword(values?.Reset)).then(async (res) => {
          if (res.type === 'success') {
            Navigate('/authentication/sign-in');
            toast.success(res.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(res.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }).catch((e) => {
          toast.error('Something went wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      }
    }
  });
  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 3 hours
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
            <MDBox mb={4}>
              <MDInput type="email" label="Email" variant="standard"
                name="Reset.email"
                value={formik.values.Reset.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                fullWidth />
              {formik.errors.Reset?.email && formik.touched.Reset?.email && (
                <Alert severity="error" sx={{
                  height: 50
                }}><small>{formik.errors.Reset.email}</small></Alert>
              )}
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="info" type="submit" fullWidth>
                Reset
              </MDButton>
            </MDBox>
            <MDTypography
              component="a"
              href="/authentication/sign-in"
              variant="button"
              fontWeight="bold"
              color="info"
              textGradient
            >
              Sign-In
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
