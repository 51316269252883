import {
    CUSTOMERDATA,
    CUSTOMERLIST,
    CUSTOMERINSTALLERDATA,
    INSTALLATIONREPORTRESPONSE
} from "../actions/types";

const initialState = {
    data: null,
    paramsData: null,
    list: null,
    customerInstallerData: null,
    reportResponse: null
};
function customerReducer(customersData = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CUSTOMERDATA:
            // add customer data in state
            return { ...customersData, data: payload?.customersResponse, paramsData: { ...customersData?.paramsData?.params, ...payload.params } };
        case CUSTOMERLIST:
            // add customer list in state
            return { ...customersData, list: payload?.customersList };
        case CUSTOMERINSTALLERDATA:
            // add customer's installer data
            return { customerInstallerData: payload?.customerInstallerResponse };
        case INSTALLATIONREPORTRESPONSE:
            // add customer's installation report response
            return { reportResponse: payload?.reportResponse };
        default:
            return customersData;
    }
}

export default customerReducer;  