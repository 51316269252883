// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/background.png";
import { useFormik } from "formik";
import { useDispatch } from 'react-redux';
import * as Yup from "yup";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Alert, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { login } from "redux/actions/auth";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Basic() {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  // validation
  const formik = useFormik({
    initialValues: {
      Login: {
        email: "",
        password: ""
      }
    },
    validationSchema: Yup.object({
      Login: Yup.object({
        email: Yup.string()
          .email("Invalid email address")
          .required("Email is required"),
        password: Yup.string().required("Password is required")
      })
    }),
    onSubmit: async (values) => {
      dispatch(login({ email: values.Login.email, password: values.Login.password })).then((res) => {
        if (res.type === 'success') {
          Navigate('/dashboard');
          toast.success(res.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(res.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).catch((e) => {
        toast.error('Something went wrong', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    }
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
            <MDBox mb={2}>
              <MDInput type="email" label="Email"
                name="Login.email"
                value={formik.values.Login.email}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                fullWidth
              />
              {formik.errors.Login?.email && formik.touched.Login?.email &&
                <Alert severity="error" sx={{
                  height: 50
                }}><small>{formik.errors.Login.email}</small></Alert>
              }
            </MDBox>
            <MDBox mb={2}>
              {/* <MDInput type="password" label="Password"
                name="Login.password"
                value={formik.values.Login.password}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                fullWidth
              /> */}
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  name="Login.password"
                  value={formik.values.Login.password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
                {formik.errors.Login?.password && formik.touched.Login?.password &&
                  <Alert severity="error" sx={{
                    height: 50
                  }}><small>{formik.errors.Login.password}</small></Alert>
                }
              </FormControl>
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={1}>
              <MDTypography
                component="a"
                href="/forgot/password"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Forgot Your Password
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" type="submit" color="info" fullWidth>
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
