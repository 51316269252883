// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "commonComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commonComponents/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import { Alert, Card } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { fetchInstallers } from "redux/actions/installers";
import { toast } from "react-toastify";
import * as Yup from "yup";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { PickerOverlay } from "filestack-react";
import { useMemo, useState } from "react";
import { editInstaller } from "redux/actions/installers";
import { useTranslation } from 'react-i18next';

function Profile() {
    let loggedUserData = JSON.parse(localStorage.getItem('loggedUserData'));
    const FILESTACK_KEY = process.env.REACT_APP_FILESTACK_API_KEY;
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const [showPickerLay, setShowPickerLay] = useState(false);
    const data = useSelector(state => state?.installers?.data);
    const [uploadProfile, setUploadProfile] = useState('');
    const { t } = useTranslation();
    useMemo(() => {
        const fetch = async () => {
            // setLoader(true);
            if (loggedUserData?.user.role === 'installer') {
                await dispatch(fetchInstallers(null, loggedUserData?.user?.userId));
            }
            // Call Fetch project dispatch function
            // setLoader(false);
        }
        fetch();
    }, []);
    // validation
    const formik = useFormik({
        initialValues: {
            Add: {
                url: ""
            }
        },
        validationSchema: Yup.object({
            Add: Yup.object({
                url: Yup.string().required("Upload Image is required")
            })
        }),
        onSubmit: async (values) => {
            if (values?.Add) {
                let data = {
                    logoUrl: values.Add.url,
                }
                dispatch(editInstaller(data, loggedUserData?.user?.userId)).then(async (res) => {
                    if (res.type === 'success') {
                        await dispatch(fetchInstallers(null, loggedUserData?.user?.userId));
                        Navigate('/dashboard');
                        toast.success(res.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        toast.error(res.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }).catch((e) => {
                    toast.error('Something went wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            }
        }
    });
    // On browse click handle filestack picker
    const handleFileStack = () => {
        setShowPickerLay(true);
    }
    // On success/fail of file upload handle formik values of all photos for submiting form values
    const handleUploadImage = (res) => {
        if (res.filesUploaded) {
            setUploadProfile(res.filesUploaded[0]?.url);
            formik.initialValues.Add.url = res.filesUploaded[0]?.url;
            if (formik.errors?.Add) {
                formik.errors.Add.url = '';
            }
        }
        setShowPickerLay(false);
    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <MDTypography variant="h6" color="white">
                                            {t('general.Profile Information')}
                                        </MDTypography>
                                    </Grid>
                                </Grid>

                            </MDBox>
                            <Grid item xs={12}>
                                <MDBox pt={4} pb={3} px={3}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <span style={{ fontSize: '15px' }}>{t("general.User Name")} : </span>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <MDBox mb={2}>
                                                <span style={{ fontSize: '15px' }}>{loggedUserData?.user?.userName ? loggedUserData?.user?.userName : '-'}</span>
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <span style={{ fontSize: '15px' }}>{t("general.Email")} : </span>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <MDBox mb={2}>
                                                <span style={{ fontSize: '15px' }}>{loggedUserData?.user?.email}</span>
                                            </MDBox>
                                        </Grid>
                                        {loggedUserData?.user?.role === 'installer' &&
                                            <>
                                                <Grid item xs={4}>
                                                    <span style={{ fontSize: '15px' }}>{t("general.Upload Profile")} : </span>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <MDBox mb={2}>
                                                        <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
                                                            <MDBox mb={2}>
                                                                <MDButton variant="gradient" color="info" type="button" onClick={handleFileStack}>
                                                                    {t("action.Browse")}
                                                                </MDButton>
                                                                {showPickerLay &&
                                                                    <PickerOverlay
                                                                        apikey={FILESTACK_KEY}
                                                                        pickerOptions={{
                                                                            onClose: () => {
                                                                                // update your state
                                                                                setShowPickerLay(false);
                                                                            }
                                                                        }}
                                                                        onUploadDone={(res) => handleUploadImage(res)}
                                                                    />
                                                                }
                                                            </MDBox>
                                                            {data?.results[0]?.Installer?.logoUrl && !uploadProfile ?
                                                                <img src={data?.results[0]?.Installer?.logoUrl} width='50%' height='50%' alt="Logo"></img>
                                                                : ''}
                                                            {uploadProfile ?
                                                                <a href={uploadProfile} target="_blank" rel="noreferrer">
                                                                    <MDBox
                                                                        name="Add.url"
                                                                        value={formik.values.Add.url}
                                                                        component="img"
                                                                        src={uploadProfile}
                                                                        alt={formik.values.Add.url}
                                                                        borderRadius="md"
                                                                        shadow="md"
                                                                        width="50%"
                                                                        height="50%"
                                                                        position="relative"
                                                                        zIndex={1}
                                                                        title="Click To Open"
                                                                    />
                                                                </a> : <>  {formik.errors.Add?.url && formik.touched.Add?.url && (
                                                                    <Alert severity="error"><small>{formik.errors.Add.url}</small></Alert>
                                                                )}</>
                                                            }
                                                            {uploadProfile && <MDBox mt={4} mb={1}>
                                                                <MDButton variant="gradient" color="info" type="submit">
                                                                    {t("action.Upload")}
                                                                </MDButton>&nbsp;
                                                                <MDButton variant="gradient" color="primary" onClick={() => Navigate('/profile')}>
                                                                    {t("action.Cancel")}
                                                                </MDButton>
                                                            </MDBox>}
                                                        </MDBox>
                                                    </MDBox>
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                </MDBox>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            {/* <Footer /> */}
        </DashboardLayout>
    );
}

export default Profile;