// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "commonComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commonComponents/Navbars/DashboardNavbar";
// import Footer from "commonComponents/Footer";
import DataTable from "commonComponents/Tables/DataTable";
import { useTranslation } from 'react-i18next';

// Data
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInstallers } from "redux/actions/installers";
import { Icon, Switch } from "@mui/material";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import breakpoints from "assets/theme/base/breakpoints";

function Installers() {
    const [mobileView, setMobileView] = useState(false);
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const list = useSelector(state => state?.installers?.data);
    const [checked, setChecked] = useState(true);
    const { t } = useTranslation();
    let columns = [];
    let sessionListRow = [];
    const handleInstallerEdit = (id) => {
        // get edit installer data
        Navigate(`/edit/installers/${id}`);
    };
    const handleInsatllerList = async (event) => {
        setChecked(event.target.checked);
        if (event?.target?.checked) {
            await dispatch(fetchInstallers(null, null, 0));
        } else {
            await dispatch(fetchInstallers(null, null, 1));
        }
    };
    if (list?.results) {
        columns = [
            { Header: t("general.First Name"), accessor: "fname", align: "left" },
            { Header: t("general.Last Name"), accessor: "lname", align: "left" },
            { Header: t("general.Email"), accessor: "email", align: "left" },
            { Header: t("general.Action"), accessor: "action", align: "center" },
        ]
        /* 
        * Set rows of project data array for dataTable list.
        * Returns rows, columns, project id and modal states
        */
        let rowObj = {};
        for (let data of list?.results) {
            rowObj['fname'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data.Installer.firstName}
            </MDTypography>;
            rowObj['lname'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data.Installer.lastName ? data.Installer.lastName : '-'}
            </MDTypography>;
            rowObj['email'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data.email ? data.email : '-'}
            </MDTypography>
            rowObj['action'] = (<>
                <MDTypography type="button" variant="body" color="info" onClick={() => handleInstallerEdit(data.userId)}>
                    <Icon>edit</Icon>
                </MDTypography>
            </>)
            sessionListRow.push(rowObj);
            rowObj = {};
        }
    }

    // Call dashboard API to get data
    /* Fetch Project data [if fetch data changed then only dispatch func will be called] */
    useMemo(() => {
        const fetch = async () => {
            setLoader(true);
            // Call Fetch project dispatch function
            await dispatch(fetchInstallers());
            setLoader(false);
        }
        fetch();
    }, []);
    useEffect(() => {
        // A function that sets the display state for the DefaultFormMobile.
        function displayMobileView() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true);
            } else {
                setMobileView(false);
            }
        }
        /** 
         The event listener that's calling the displayMobileView function when 
         resizing the window.
        */
        window.addEventListener("resize", displayMobileView);

        // Call the displayMobileView function to set the state with the initial value.
        displayMobileView();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", displayMobileView);
    }, []);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-2}
                                py={0.5}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <Grid container>
                                    <Grid item xs={6}>
                                        <MDTypography style={{marginTop:"5px", fontSize: "17px"}} variant="h6" color="white">
                                            {t('general.Installers')}
                                        </MDTypography>
                                    </Grid>
                                    <Grid item xs={mobileView ? 12 : 6} >
                                        <div style={{ float: 'right' }} >
                                            <Switch
                                                sx={{
                                                    '& .MuiSwitch-switchBase': {
                                                        '&.Mui-checked': {
                                                            color: 'green',
                                                            '& + .MuiSwitch-track': {
                                                                background: 'green',
                                                            },
                                                        },
                                                        '&.Mui-disabled.MuiSwitch-thumb': {
                                                            color: 'green',
                                                        },
                                                    },
                                                    '& .MuiSwitch-thumb': {
                                                        color: !checked && 'green',
                                                    },
                                                    '& .MuiSwitch-track': {
                                                        backgroundColor: 'green',
                                                    },
                                                }}
                                                // color='success'
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                onChange={handleInsatllerList} />
                                            <label style={{ fontSize: "15px", color: "white", marginRight: '10px' }}>{t('general.Active')}/{t('general.In Active')}</label>
                                            {!mobileView && <MDButton variant="outlined" color="light" onClick={() => Navigate('/create/installers')}>{t('customAction.Create New Installer')}</MDButton>}
                                        </div>
                                        {mobileView && <MDButton style={{ float: 'right' }} variant="outlined" color="light" onClick={() => Navigate('/create/installers')}>{t('customAction.Create New Installer')}</MDButton>}
                                    </Grid>
                                </Grid>

                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns: columns, rows: sessionListRow }}
                                    isSorted={false}
                                    entriesPerPage={false}
                                    showTotalEntries={true}
                                    noEndBorder
                                    loader={loader}
                                    pagination={{ count: list?.totalPages ? list?.totalPages : 5, fetchtype: 'fetchInstaller' }}
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            {/* <Footer /> */}
        </DashboardLayout>
    );
}

export default Installers;
