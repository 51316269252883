import {
    PRICESETTINGDATA
} from "../actions/types";
import axios from "axios";
import Service from "service";

// Actions
export const fetchPriceSetting = (page, filter) => async dispatch => {
    try {
        const loggedRole = JSON.parse(localStorage.getItem('roles'));
        let params = {
            type: 'PriceSetting',
        };
        if (page) {
            params['page'] = page;
        }
        if (filter?.customerId) {
            params['customerId'] = filter.customerId;
        }
        if (filter?.installerId) {
            params['installerId'] = filter.installerId;
        }
        if (loggedRole === 'installer') {
            let id = JSON.parse(localStorage.getItem('loggedUserData'));
            params['installerId'] = id?.user?.userId;
        }
        // Calling Session API
        const res = await axios.get(`${process.env.REACT_APP_BaseUrl}/api/v1/price-settings/list`, Service.config(params));
        if (res.data) {
            dispatch({
                type: PRICESETTINGDATA,
                payload: {
                    priceSettingResponse: res.data.data,
                    params: params
                }
            });
            return Promise.resolve(res.data.data);
        }
    } catch (e) {
        return Promise.reject(e);
    }
}
export const editPriceSetting = (data) => async () => {
    try {
        // Calling edit margin fro price setting API
        const res = await axios.put(`${process.env.REACT_APP_BaseUrl}/api/v1/price-settings/update/${data.optimileDeviceId}`, { margin: data.margin }, Service.config());
        if (res.data) {
            return Promise.resolve(res.data);
        }
    } catch (e) {
        return Promise.reject(e);
    }
}