import {
    CUSTOMERDATA,
    CUSTOMERLIST,
    CUSTOMERINSTALLERDATA,
    INSTALLATIONREPORTRESPONSE
} from "../actions/types";
import axios from "axios";
import Service from "service";
import Helpers from "helpers";
// Actions
export const fetchCustomer = (page, filter, userId, csv) => async dispatch => {
    try {
        const loggedRole = JSON.parse(localStorage.getItem('roles'));
        let params = {
            type: 'Customer',
        };
        if (page) {
            params['page'] = page;
        }
        if (filter?.customerId || filter?.userId) {
            params['userId'] = filter.customerId ? filter.customerId : filter.userId;
        }
        if (filter) {
            params['installerId'] = filter.installerId;
        }
        if (userId) {
            params['userId'] = userId;
        }
        if (loggedRole === 'installer') {
            let id = JSON.parse(localStorage.getItem('loggedUserData'));
            params['installerId'] = id?.user?.userId;
        }
        if (csv) {
            params['csv'] = true;
        }
        // Calling Login API
        const res = await axios.get(`${process.env.REACT_APP_BaseUrl}/api/v1/customers/list`, Service.config(params));
        if (res.data && csv) {
            Helpers.csvDownload(res.data, res.headers);
            return;
        }
        if (res.data) {
            dispatch({
                type: CUSTOMERDATA,
                payload: {
                    customersResponse: res.data.data,
                    params: params
                }
            });
            return Promise.resolve(res.data.data);
        }
    } catch (e) {
        return Promise.reject(e);
    }
}
export const fetchCustomerList = (userId) => async dispatch => {
    try {
        const loggedRole = JSON.parse(localStorage.getItem('roles'));
        let params = {
            type: 'Customer',
        };
        if (loggedRole === 'installer') {
            let id = JSON.parse(localStorage.getItem('loggedUserData'));
            params['installerId'] = id?.user?.userId;
        }
        // Calling Login API
        const res = await axios.get(`${process.env.REACT_APP_BaseUrl}/api/v1/customers/list`, Service.config(params));
        if (res.data) {
            dispatch({
                type: CUSTOMERLIST,
                payload: {
                    customersList: res.data.data,
                    params: params
                }
            });
            return Promise.resolve(res.data.data);
        }
    } catch (e) {
        return Promise.reject(e);
    }
}
export const addCustomer = (customerData) => async () => {
    try {
        // Calling add installer API
        const res = await axios.post(`${process.env.REACT_APP_BaseUrl}/api/v1/customers/create`, customerData, Service.config());
        if (res.data) {
            return Promise.resolve(res.data);
        }
    } catch (e) {
        return Promise.reject(e);
    }
}
export const editCustomer = (customerData, customerId) => async () => {
    try {
        // Calling edit installer API
        const res = await axios.put(`${process.env.REACT_APP_BaseUrl}/api/v1/customers/update/${customerId}`, customerData, Service.config());
        if (res.data) {
            return Promise.resolve(res.data);
        }
    } catch (e) {
        return Promise.reject(e);
    }
}
export const fetchInstallerForCustomer = () => async dispatch => {
    try {
        // get customer's installer data
        let id = JSON.parse(localStorage.getItem('loggedUserData'));
        const res = await axios.get(`${process.env.REACT_APP_BaseUrl}/api/v1/customers/get-installer-data/${id?.user?.userId}`, Service.config());
        if (res.data) {
            dispatch({
                type: CUSTOMERINSTALLERDATA,
                payload: {
                    customerInstallerResponse: res.data.data
                }
            });
            return Promise.resolve(res.data.data);
        }
    } catch (e) {
        return Promise.reject(e);
    }
}
export const fetchInstallationReportConfirmation = (id) => async dispatch => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_BaseUrl}/api/v1/customers/approveInstallationReport/${id}`);
        if (res.data) {
            dispatch({
                type: INSTALLATIONREPORTRESPONSE,
                payload: {
                    reportResponse: res.data
                }
            });
            return Promise.resolve(res.data);
        }
    } catch (e) {
        return Promise.reject(e);
    }
}