import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { useState } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useFormik } from 'formik';
import { fetchOverview } from 'redux/actions/overview';
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import breakpoints from 'assets/theme/base/breakpoints';
import { Grid } from '@mui/material';

export default function Filter() {
    const [mobileView, setMobileView] = useState(false);
    const { t } = useTranslation();
    const [selectedOptions, setSelectedOptions] = useState();
    const dispatch = useDispatch();
    const installerList = useSelector(state => state.installers.data);
    const loggedRole = JSON.parse(localStorage.getItem('roles'));
    let optionList = [];
    if (installerList?.results) {
        for (let data of installerList?.results) {
            if (data.userId) {
                optionList.push(
                    { value: data.userId, label: data.Installer.firstName + ' ' + data.Installer.lastName }
                )
            }
        }
    }
    const handleSelect = (data) => {
        setSelectedOptions(data);
    }
    const formik = useFormik({
        initialValues: {
            installerId: ""
        },
        onSubmit: async (values) => {
            if (selectedOptions?.value) {
                formik.values.installerId = selectedOptions.value;
            }
            if (selectedOptions?.value) {
                await dispatch(fetchOverview(null, { installerId: selectedOptions?.value }));
            }
        }
    });
    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: "transparent",
            fontSize: 17,
            borderRadius: state.isFocused ? "3px 3px 0 0" : 7,
            boxShadow: state.isFocused ? null : null,
        })
    };
    const resetData = async () => {
        setSelectedOptions('');
        await dispatch(fetchOverview(null, null));
    }
    useEffect(() => {
        // A function that sets the display state for the DefaultFormMobile.
        function displayMobileView() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true);
            } else {
                setMobileView(false);
            }
        }
        /** 
         The event listener that's calling the displayMobileView function when 
         resizing the window.
        */
        window.addEventListener("resize", displayMobileView);

        // Call the displayMobileView function to set the state with the initial value.
        displayMobileView();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", displayMobileView);
    }, []);
    return (
        <Grid>
            <MDBox component="form" role="form" m={1}
                display="flex"
                justifyContent="flex-end"
                onSubmit={formik.handleSubmit}
            >
                {loggedRole !== 'installer' &&
                    <Grid>
                        <FormControl style={{
                            'width': '300px',
                        }}>
                            <Select
                                styles={customStyles}
                                options={optionList}
                                placeholder={t('general.Select') + ' ' + t('general.Installer Name')}
                                value={selectedOptions}
                                onChange={handleSelect}
                                isSearchable={true}
                                autoWidth={false}
                            />
                            {mobileView && <br />}
                        </FormControl>&nbsp;
                        <MDButton variant="gradient" color="info" type="submit">
                            {t('action.Search')}
                        </MDButton>&nbsp;
                        <MDButton variant="gradient" type="reset" color="primary" onClick={resetData}>
                            {t('action.Reset')}
                        </MDButton>
                    </Grid>
                }
            </MDBox >
        </Grid >
    );
}