import {
    SESSIONDATA
} from "../actions/types";

const initialState = {
    list: [],
    paramsData: null,
    data: null
};
function sessionReducer(sessionsData = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SESSIONDATA:
            // add session data in state
            return { ...sessionsData, data: payload?.sessionResponse, paramsData: { ...sessionsData?.paramsData, ...payload.params } };
        default:
            return sessionsData;
    }
}

export default sessionReducer;  