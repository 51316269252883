/** 
  All of the routes for the React are added here

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  Routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
import Roles from 'config/Roles';
import Dashboard from "layouts/dashboard";
import SignIn from "layouts/authentication/sign-in";

// @mui icons
import Icon from "@mui/material/Icon";
import Installers from "layouts/installers";
import AddInstallers from "layouts/installers/add";
import Pricing from 'layouts/pricing';
import EditInstaller from 'layouts/installers/edit';
import Cover from 'layouts/authentication/reset-password/cover';
import SetPassword from 'layouts/authentication/set-password';
import Customers from 'layouts/customer';
import AddCustomer from 'layouts/customer/Add';
import EditCustomer from 'layouts/customer/Edit';
import ResetPassword from 'layouts/authentication/reset-password/reset';
import Profile from 'layouts/profile';
import Overview from 'layouts/overview';
import Contact from 'layouts/contact';
// If creating routing for add edit or any active then URL must be [create/modulename] or [edit/modulename/id]
export default [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    permission: [
      Roles.ADMIN,
      Roles.INSTALLER,
      Roles.CUSTOMER
    ],
  },
  {
    type: "collapse",
    name: "Contact",
    key: "conctact",
    icon: <Icon fontSize="small">contacts</Icon>,
    route: "/contact",
    component: <Contact />,
    permission: [
      Roles.CUSTOMER
    ],
  },
  {
    type: "collapse",
    name: "Installers",
    key: "installers",
    icon: <Icon fontSize="small">supervised_user_circle</Icon>,
    route: "/installers",
    component: <Installers />,
    permission: [
      Roles.ADMIN
    ],
  },
  {
    type: "collapse",
    key: "create-installer",
    route: "/create/installers",
    component: <AddInstallers />,
    permission: [
      Roles.ADMIN
    ],
  },
  {
    type: "collapse",
    key: "edit-installer",
    route: "/edit/installers/:id",
    component: <EditInstaller />,
    permission: [
      Roles.ADMIN
    ],
  },
  {
    type: "collapse",
    name: "Pricing",
    key: "pricing",
    icon: <Icon fontSize="small">ev_station</Icon>,
    route: "/pricing",
    component: <Pricing />,
    permission: [
      Roles.ADMIN,
      Roles.INSTALLER
    ],
  },
  {
    type: "collapse",
    name: "Overview",
    key: "overview",
    icon: <Icon fontSize="small">preview</Icon>,
    route: "/overview",
    component: <Overview />,
    permission: [
      Roles.ADMIN,
      Roles.INSTALLER
    ],
  },
  {
    type: "collapse",
    name: "Customers",
    key: "customers",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/customers",
    component: <Customers />,
    permission: [
      Roles.ADMIN,
      Roles.INSTALLER
    ],
  },
  {
    type: "collapse",
    key: "customers",
    route: "/create/customers",
    component: <AddCustomer />,
    permission: [
      Roles.ADMIN,
      Roles.INSTALLER
    ],
  },
  {
    type: "collapse",
    key: "edit-customer",
    route: "/edit/customers/:id",
    component: <EditCustomer />,
    permission: [
      Roles.ADMIN,
      Roles.INSTALLER
    ],
  },
  {
    type: "collapse",
    key: "set-password",
    route: "/set/password",
    component: <SetPassword />,
    permission: [
      Roles.ADMIN,
      Roles.INSTALLER,
      Roles.CUSTOMER
    ],
  },
  {
    type: "collapse",
    key: "profile",
    route: "/profile",
    component: <Profile />,
    permission: [
      Roles.ADMIN,
      Roles.INSTALLER,
      Roles.CUSTOMER
    ],
  },
  {
    type: "collapse",
    key: "reset-password",
    route: "/users/reset-password",
    component: <ResetPassword />
  },
  {
    type: "collapse",
    key: "set-password",
    route: "/forgot/password",
    component: <Cover />
  },
  {
    type: "collapse",
    // name: "Sign In",
    key: "sign-in",
    // icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
];