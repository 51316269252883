import {
    AUTH,
    LOGOUT
} from "../actions/types";
import axios from "axios";
import Service from "service";
// Actions
export const login = ({ email, password }) => async dispatch => {
    try {
        // Calling Login API
        const res = await axios.post(`${process.env.REACT_APP_BaseUrl}/api/v1/users/login`, { email, password });
        if (res.data) {
            dispatch({
                type: AUTH,
                payload: res.data
            });
            return Promise.resolve(res.data);
        }
    } catch (e) {
        return Promise.reject(e);
    }
}
export const logout = () => async dispatch => {
    try {
        localStorage.removeItem('token');
        localStorage.removeItem('loggedUserData');
        localStorage.removeItem('roles');
        dispatch({
            type: LOGOUT,
            payload: ''
        });
        return Promise.resolve();
    } catch (e) {
        return Promise.reject(e);
    }
}
export const resetPassword = (data) => async dispatch => {
    try {
        let loggedData = {};
        if (data.token) {
            loggedData['token'] = data.token;
            loggedData['password'] = data.password;
        } else {
            let id = JSON.parse(localStorage.getItem('loggedUserData'));
            loggedData['userId'] = id?.user?.userId;
            loggedData['oldPassword'] = data.oldPassword;
            loggedData['password'] = data.password;
        }
        // get userId of logged user
        const res = await axios.post(`${process.env.REACT_APP_BaseUrl}/api/v1/users/reset-password`, loggedData, Service.config());
        if (res.data) {
            return Promise.resolve(res.data);
        }
    } catch (e) {
        return Promise.reject(e);
    }
}
export const forgotPassword = (data) => async dispatch => {
    try {
        if (data) {
            const res = await axios.post(`${process.env.REACT_APP_BaseUrl}/api/v1/users/forgot-password`, { email: data.email });
            if (res.data) {
                return Promise.resolve(res.data);
            }
        }
    } catch (e) {
        return Promise.reject(e);
    }
}