import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { Alert, Checkbox, CircularProgress } from "@mui/material";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "commonComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commonComponents/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";
import { addInstaller } from "redux/actions/installers";
import { fetchInstallers } from "redux/actions/installers";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import breakpoints from "assets/theme/base/breakpoints";

function AddInstaller() {
    const [mobileView, setMobileView] = useState(false);
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const { t } = useTranslation();
    // validation
    const formik = useFormik({
        initialValues: {
            Add: {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                active: "",
                role: "installer"
            }
        },
        validationSchema: Yup.object({
            Add: Yup.object({
                firstName: Yup.string().required("First Name is required"),
                lastName: Yup.string().required("Last Name is required"),
                email: Yup.string().required("Email is required"),
                password: Yup.string().required("Password is required"),
            })
        }),
        onSubmit: async (values) => {
            if (values?.Add) {
                setLoader(true);
                let data = {
                    firstName: values.Add.firstName,
                    lastName: values.Add.lastName,
                    email: values.Add.email,
                    password: values.Add.password,
                    role: values.Add.role,
                    active: values.Add.active ? '1' : '0',
                }
                dispatch(addInstaller(data)).then(async (res) => {
                    setLoader(false);
                    if (res.type === 'success') {
                        await dispatch(fetchInstallers());
                        Navigate('/installers');
                        toast.success(res.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        toast.error(res.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }).catch((e) => {
                    setLoader(false);
                    toast.error('Something went wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            }
        }
    });
    useEffect(() => {
        // A function that sets the display state for the DefaultFormMobile.
        function displayMobileView() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true);
            } else {
                setMobileView(false);
            }
        }
        /** 
         The event listener that's calling the displayMobileView function when 
         resizing the window.
        */
        window.addEventListener("resize", displayMobileView);

        // Call the displayMobileView function to set the state with the initial value.
        displayMobileView();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", displayMobileView);
    }, []);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={1}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <MDTypography variant="h6" color="white">
                                            {t('general.Create Installer')}
                                        </MDTypography>
                                    </Grid>
                                </Grid>

                            </MDBox>
                            <Grid item xs={mobileView ? 12 : 6}>
                                <MDBox pt={4} pb={3} px={3}>
                                    <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
                                        <MDBox mb={2}>
                                            <MDInput type="text" label={t("general.First Name")}
                                                name="Add.firstName"
                                                value={formik.values.Add.firstName}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                fullWidth
                                            />
                                            {formik.errors.Add?.firstName && formik.touched.Add?.firstName && (
                                                <Alert severity="error" sx={{
                                                    height: 50
                                                }}><small>{t(`validation.${formik.errors.Add.firstName}`)}</small></Alert>
                                            )}
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput type="text" label={t("general.Last Name")}
                                                name="Add.lastName"
                                                value={formik.values.Add.lastName}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                fullWidth
                                            />
                                            {formik.errors.Add?.lastName && formik.touched.Add?.lastName && (
                                                <Alert severity="error" sx={{
                                                    height: 50
                                                }}><small>{t(`validation.${formik.errors.Add.lastName}`)}</small></Alert>
                                            )}
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput type="text" label={t("general.Email")}
                                                name="Add.email"
                                                value={formik.values.Add.email}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                fullWidth
                                            />
                                            {formik.errors.Add?.email && formik.touched.Add?.email && (
                                                <Alert severity="error" sx={{
                                                    height: 50
                                                }}><small>{t(`validation.${formik.errors.Add.email}`)}</small></Alert>
                                            )}
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput type="password" label={t("general.Password")}
                                                name="Add.password"
                                                value={formik.values.Add.password}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                fullWidth
                                            />
                                            {formik.errors.Add?.password && formik.touched.Add?.password && (
                                                <Alert severity="error" sx={{
                                                    height: 50
                                                }}><small>{t(`validation.${formik.errors.Add.password}`)}</small></Alert>
                                            )}
                                        </MDBox>
                                        <Checkbox name="Add.active"
                                            value={formik.values.Add.active}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange} /><span style={{ 'fontSize': '15px' }}>{t('general.Active')}</span>
                                        <MDBox mt={4} mb={1}>
                                            <MDButton variant="gradient" color="info" disabled={loader && true} type="submit">
                                                {t('action.Add')} &nbsp; {loader && <CircularProgress size={'20px'} />}
                                            </MDButton>&nbsp;
                                            <MDButton variant="gradient" color="primary" onClick={() => Navigate('/installers')}>
                                                {t('action.Cancel')}
                                            </MDButton>
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            {/* <Footer /> */}
        </DashboardLayout>
    );
}

export default AddInstaller;
