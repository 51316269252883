import {
    SESSIONDATA
} from "../actions/types";
import axios from "axios";
import Service from "service";
import Moment from "moment";
import Helpers from "helpers";

// Actions
export const fetchSessions = (page, filter, csv) => async dispatch => {
    try {
        let params = {
            type: 'Session',
            sortOrder: 'desc',
            sortField: 'timeStartedSession'
        };
        const loggedRole = JSON.parse(localStorage.getItem('roles'));
        const id = JSON.parse(localStorage.getItem('loggedUserData'));
        // Check role of logged data
        if (loggedRole === 'installer') {
            params['installerId'] = id.user.userId;
        }
        if (loggedRole === 'customer') {
            params['customerId'] = id.user.userId;
        }
        if (page || filter?.page) {
            params['page'] = page ? page : filter?.page;
        }
        if (filter?.startPeriod || filter?.endPeriod) {
            params['startPeriod'] = Moment(filter?.startPeriod ? filter?.startPeriod : filter.date[0].startDate).format('YYYY-MM-DD');
            params['endPeriod'] = Moment(filter?.endPeriod ? filter?.endPeriod : filter.date[0].endDate).format('YYYY-MM-DD');
        }
        if (filter?.customerId) {
            params['customerId'] = filter.customerId;
        }
        if (filter?.sortOrder && filter?.sortField) {
            params['sortOrder'] = filter.sortOrder;
            params['sortField'] = filter.sortField;
        }
        if (csv) {
            params['csv'] = true;
        }
        // Get TimeZone for session data
        let timeZone = encodeURIComponent(new Date().toString().split(" ")[5].substr(3, 5));
        // Calling Session API
        const res = await axios.get(`${process.env.REACT_APP_BaseUrl}/api/v1/sessions/get-sessions?timeZone=${timeZone}`, Service.config(params));
        if (res.data && csv) {
            Helpers.csvDownload(res.data, res.headers);
            return;
        }
        if (res.data) {
            dispatch({
                type: SESSIONDATA,
                // payload: res.data.data
                payload: {
                    sessionResponse: res.data.data,
                    params: params
                }
            });
            return Promise.resolve(res.data.data);
        }
    } catch (e) {
        return Promise.reject(e);
    }
}