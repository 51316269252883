import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "commonComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commonComponents/Navbars/DashboardNavbar";
import DataTable from "commonComponents/Tables/DataTable";

// Data
import Filter from "./filter";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOverview } from "redux/actions/overview";
import { fetchInstallers } from "redux/actions/installers";
import { useTranslation } from 'react-i18next';

function Overview() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const list = useSelector(state => state.overview.data);
    const [loader, setLoader] = useState(false);
    let columns = [];
    let overviewListRow = [];
    if (list?.sessions) {
        columns = [
            { Header: t("general.Installer Name"), accessor: "installerName", align: "left" },
            { Header: t("general.Optimile Device Id"), accessor: "deviceId", align: "center" },
            { Header: t("general.Selling") + ' ' + t("general.Price"), accessor: "sellingPrice", align: "left" },
            { Header: t("general.Roaming Cost"), accessor: "roamingCost", align: "left" },
            { Header: t("general.Year") + '/' + t("general.Month"), accessor: "yearMonth", align: "left" },
            { Header: t("general.Consumed") + ' KWH', accessor: "consumed", align: "left" },
            { Header: t("general.Total Turnover"), accessor: "turnover", align: "center" },
            { Header: t("general.Sales") + ' ' + t("general.Installers"), accessor: "saleInstaller", align: "center" },
            { Header: t("general.Revenue Host"), accessor: "revenueHost", align: "center" }
        ]
        /* 
        * Set rows of project data array for dataTable list.
        * Returns rows, columns, project id and modal states
        */
        let rowObj = {};
        for (let data of list.sessions.results) {
            rowObj['installerName'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data.installerName}
            </MDTypography>;
            rowObj['deviceId'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data['DeviceToSession.optimileDeviceId'] ? data['DeviceToSession.optimileDeviceId'] : '-'}
            </MDTypography>;
            rowObj['sellingPrice'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data.sellingPrice ? `€${data.sellingPrice}` : '-'}
            </MDTypography>;
            rowObj['roamingCost'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data.roamingCost ? `€${data.roamingCost}` : '-'}
            </MDTypography>
            rowObj['yearMonth'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data.year + '/' + data.Period}
            </MDTypography>
            rowObj['consumed'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data?.consumedKwh ? data.consumedKwh + 'KWH' : '-'}
            </MDTypography>
            rowObj['turnover'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data.totalTurnOver ? `€${data.totalTurnOver}` : ''}
            </MDTypography>
            rowObj['saleInstaller'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data.salesInstallerSum ? `€${data.salesInstallerSum}` : '-'}
            </MDTypography>
            rowObj['revenueHost'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data.revenueHost ? `€${data.revenueHost}` : '-'}
            </MDTypography>
            overviewListRow.push(rowObj);
            rowObj = {};
        }
    }

    // Call dashboard API to get data
    /* Fetch overview data [if fetch data changed then only dispatch func will be called] */
    useMemo(() => {
        const fetch = async () => {
            setLoader(true);
            // Call Fetch project dispatch function
            await dispatch(fetchOverview());
            await dispatch(fetchInstallers());
            setLoader(false);
        }
        fetch();
    }, []);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Filter />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={1}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <MDTypography variant="h6" color="white">
                                            {t('general.Overview')}
                                        </MDTypography>
                                    </Grid>
                                </Grid>

                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns: columns, rows: overviewListRow }}
                                    isSorted={false}
                                    entriesPerPage={false}
                                    showTotalEntries={true}
                                    noEndBorder
                                    loader={loader}
                                    pagination={{ count: list?.sessions?.totalPages ? list?.sessions?.totalPages : 5, fetchtype: 'fetchOverview' }}
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default Overview;
