import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Checkbox, CircularProgress } from "@mui/material";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "commonComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commonComponents/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { editCustomer } from "redux/actions/customer";
import { fetchCustomer } from "redux/actions/customer";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import breakpoints from "assets/theme/base/breakpoints";

function EditCustomer() {
    const [mobileView, setMobileView] = useState(false);
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const { id } = useParams();
    const { t } = useTranslation();
    useEffect(() => {
        if (id) {
            dispatch(fetchCustomer(null, null, id));
        }
    }, [id]);
    const customerData = useSelector(state => state?.customers?.data);
    // validation
    const formik = useFormik({
        initialValues: {
            Edit: {
                firstName: customerData?.results[0]?.UserToCustomer?.firstName,
                lastName: customerData?.results[0]?.UserToCustomer?.lastName,
                email: customerData?.results[0]?.email,
                active: customerData?.results[0]?.active === '1' ? true : false,
            }
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            Edit: Yup.object({
                firstName: Yup.string().required("First Name is required"),
                lastName: Yup.string().required("Last Name is required"),
                email: Yup.string().required("Email is required"),
            })
        }),
        onSubmit: async (values) => {
            if (values?.Edit) {
                setLoader(true);
                let data = {
                    firstName: values.Edit.firstName,
                    lastName: values.Edit.lastName,
                    email: values.Edit.email,
                }
                // API Needs 1 and 0 as string for active value
                if (values?.Edit?.active) {
                    data.active = '1';
                } else {
                    data.active = '0';
                }
                dispatch(editCustomer(data, id)).then(async (res) => {
                    setLoader(false);
                    if (res.type === 'success') {
                        await dispatch(fetchCustomer());
                        Navigate('/customers');
                        toast.success(res.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        toast.error(res.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }).catch((e) => {
                    setLoader(false);
                    toast.error('Something went wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            }
        }
    });
    useEffect(() => {
        // A function that sets the display state for the DefaultFormMobile.
        function displayMobileView() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true);
            } else {
                setMobileView(false);
            }
        }
        /** 
         The event listener that's calling the displayMobileView function when 
         resizing the window.
        */
        window.addEventListener("resize", displayMobileView);

        // Call the displayMobileView function to set the state with the initial value.
        displayMobileView();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", displayMobileView);
    }, []);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={1}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <MDTypography variant="h6" color="white">
                                            {t('general.Edit Customer')}
                                        </MDTypography>
                                    </Grid>
                                </Grid>

                            </MDBox>
                            <Grid item xs={mobileView ? 12 : 6}>
                                <MDBox pt={4} pb={3} px={3}>
                                    <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
                                        <MDBox mb={2}>
                                            <MDInput type="text" label={t("general.First Name")}
                                                name="Edit.firstName"
                                                value={formik.values.Edit.firstName}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                fullWidth
                                            />
                                            {formik.errors.Edit?.firstName && formik.touched.Edit?.firstName && (
                                                <Alert severity="error" sx={{
                                                    height: 50
                                                }}><small>{t(`validation.${formik.errors.Edit.firstName}`)}</small></Alert>
                                            )}
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput type="text" label={t("general.Last Name")}
                                                name="Edit.lastName"
                                                value={formik.values.Edit.lastName}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                fullWidth
                                            />
                                            {formik.errors.Edit?.lastName && formik.touched.Edit?.lastName && (
                                                <Alert severity="error" sx={{
                                                    height: 50
                                                }}><small>{t(`validation.${formik.errors.Edit.lastName}`)}</small></Alert>
                                            )}
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput type="text" label={t("general.Email")}
                                                name="Edit.email"
                                                value={formik.values.Edit.email}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                fullWidth
                                            />
                                            {formik.errors.Edit?.email && formik.touched.Edit?.email && (
                                                <Alert severity="error" sx={{
                                                    height: 50
                                                }}><small>{t(`validation.${formik.errors.Edit.email}`)}</small></Alert>
                                            )}
                                        </MDBox>
                                        <Checkbox name="Edit.active"
                                            value={formik.values.Edit.active}
                                            onBlur={formik.handleBlur}
                                            checked={formik.values.Edit.active === '1' ? true : false}
                                            onChange={formik.handleChange} /><span style={{ 'fontSize': '15px' }}>{t("general.Active")}</span>
                                        <MDBox mt={4} mb={1}>
                                            <MDButton variant="gradient" color="info" disabled={loader && true} type="submit">
                                                {t('action.Edit')}  &nbsp; {loader && <CircularProgress size={'20px'} />}
                                            </MDButton>&nbsp;
                                            <MDButton variant="gradient" color="primary" onClick={() => Navigate('/customers')}>
                                                {t('action.Cancel')}
                                            </MDButton>
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            {/* <Footer /> */}
        </DashboardLayout>
    );
}

export default EditCustomer;
