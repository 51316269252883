const Service = {
    // Set Config for API
    config: function (params) {
        let config = {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
            params: {}
        };
        switch (params?.type) {
            case 'Session':
                config['params'] = { page: params.page, startPeriod: params.startPeriod, endPeriod: params.endPeriod, customerId: params.customerId, installerId: params.installerId, csv: params.csv, sortOrder: params.sortOrder, sortField: params.sortField }
                break;
            case 'Installer':
                config['params'] = { page: params.page, active: params.active, userId: params.userId, csv: params.csv }
                break;
            case 'Customer':
                config['params'] = { page: params.page, userId: params.userId, installerId: params.installerId, csv: params.csv }
                break;
            case 'PriceSetting':
                config['params'] = { page: params.page, customerId: params.customerId, installerId: params.installerId }
                break;
            case 'Overview':
                config['params'] = { page: params.page, installerId: params.installerId, csv: params.csv }
                break;
            default:
                config['params'] = {}
        }
        return config;
    }
}

export default Service;