import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { useState } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { fetchPriceSetting } from 'redux/actions/priceSetting';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import breakpoints from 'assets/theme/base/breakpoints';
import { useEffect } from 'react';

export default function Filter() {
    const [mobileView, setMobileView] = useState(false);
    const { t } = useTranslation();
    const [selectedCustomerOptions, setSelectedCustomerOptions] = useState();
    const [selectedInstallerOptions, setSelectedInstallerOptions] = useState();
    const dispatch = useDispatch();
    const customerList = useSelector(state => state.customers.data);
    const installerList = useSelector(state => state.installers.data);
    const loggedRole = JSON.parse(localStorage.getItem('roles'));
    let optionInstallerList = [];
    if (installerList?.results) {
        for (let data of installerList?.results) {
            if (data.userId) {
                optionInstallerList.push(
                    { value: data.userId, label: data?.Installer?.firstName + ' ' + data?.Installer?.lastName }
                )
            }
        }
    }
    let optionCustomerList = [];
    if (customerList?.results) {
        for (let data of customerList?.results) {
            if (data.UserToCustomer.customerId) {
                optionCustomerList.push(
                    { value: data.userId, label: data.UserToCustomer.firstName + ' ' + data.UserToCustomer.lastName }
                )
            }
        }
    }
    const handleCustomerSelect = (data) => {
        setSelectedCustomerOptions(data);
    }
    const handleInstallerSelect = (data) => {
        setSelectedInstallerOptions(data);
    }
    const formik = useFormik({
        initialValues: {
            installerId: "",
            customerId: ""
        },
        onSubmit: async (values) => {
            if (selectedInstallerOptions?.value) {
                formik.values.installerId = selectedInstallerOptions.value;
            }
            if (selectedCustomerOptions?.value) {
                formik.values.customerId = selectedCustomerOptions.value;
            }
            if (selectedInstallerOptions?.value || selectedCustomerOptions?.value) {
                await dispatch(fetchPriceSetting(null, { installerId: selectedInstallerOptions?.value, customerId: selectedCustomerOptions?.value }));
            }
        }
    });
    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: "transparent",
            fontSize: 17,
            borderRadius: state.isFocused ? "3px 3px 0 0" : 7,
            boxShadow: state.isFocused ? null : null,
        })
    };
    const resetData = async () => {
        setSelectedInstallerOptions('');
        setSelectedCustomerOptions('');
        await dispatch(fetchPriceSetting(null, null));
    }
    useEffect(() => {
        // A function that sets the display state for the DefaultFormMobile.
        function displayMobileView() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true);
            } else {
                setMobileView(false);
            }
        }
        /** 
         The event listener that's calling the displayMobileView function when 
         resizing the window.
        */
        window.addEventListener("resize", displayMobileView);

        // Call the displayMobileView function to set the state with the initial value.
        displayMobileView();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", displayMobileView);
    }, []);
    return (
        <Grid>
            <MDBox component="form" role="form" m={1}
                display="flex"
                justifyContent="flex-end"
                onSubmit={formik.handleSubmit}
            >
                <Grid>
                    {loggedRole !== 'installer' && <FormControl style={{
                        'width': '300px',
                        'zIndex': '99',
                    }}>
                        <Select
                            styles={customStyles}
                            options={optionInstallerList}
                            placeholder={t('general.Select') + ' ' + t('general.Installer Name')}
                            value={selectedInstallerOptions}
                            onChange={handleInstallerSelect}
                            isSearchable={true}
                            autoWidth={false}
                        />
                        {mobileView && <br />}
                    </FormControl>}{!mobileView && ' '}
                    <FormControl style={{
                        'width': '300px',
                    }}>
                        <Select
                            styles={customStyles}
                            options={optionCustomerList}
                            placeholder={t('general.Select') + ' ' + t('general.Customer Name')}
                            value={selectedCustomerOptions}
                            onChange={handleCustomerSelect}
                            isSearchable={true}
                            autoWidth={false}
                        />
                        {mobileView && <br />}
                    </FormControl>{!mobileView && ' '}
                    {mobileView && <br />}
                    <MDButton variant="gradient" color="info" type="submit">
                        {t('action.Search')}
                    </MDButton>&nbsp;
                    <MDButton variant="gradient" type="reset" color="primary" onClick={resetData}>
                        {t('action.Reset')}
                    </MDButton>
                </Grid>
            </MDBox>
        </Grid >
    );
}