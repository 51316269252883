

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "commonComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commonComponents/Navbars/DashboardNavbar";
// import Footer from "commonComponents/Footer";
import DataTable from "commonComponents/Tables/DataTable";

// Data
import Filter from "./filter";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSessions } from "redux/actions/sessions";
import Helpers from "helpers";
import { fetchCustomer } from "redux/actions/customer";
import { useTranslation } from 'react-i18next';

function Dashboard() {
    const dispatch = useDispatch();
    const list = useSelector(state => state.sessions.data);
    const [loader, setLoader] = useState(false);
    const loggedRole = JSON.parse(localStorage.getItem('roles'));
    const { t } = useTranslation();
    let columns = [];
    let sessionListRow = [];
    if (list?.sessions) {
        columns = [
            { Header: t("general.Customer Name"), accessor: "customerName", align: "left", sortable: true, field: 'customerName' },
            { Header: t("general.Session"), accessor: "optimileSessionId", align: "left" },
            { Header: t("general.Price") + " KWH", accessor: "hostPricePerKwh", align: "left" },
            { Header: t("general.Start Time"), accessor: "timeStartedSession", align: "left", sortable: true },
            { Header: t("general.End Time"), accessor: "timeStoppedSession", align: "left", sortable: false },
            { Header: t("general.Total Duration"), accessor: "duration", align: "left" },
            { Header: t("general.Used Up"), accessor: "kwh", align: "center", sortable: false },
            { Header: t("general.Price"), accessor: "hostPrice", align: "center", sortable: false }
        ]
        /* 
        * Set rows of project data array for dataTable list.
        * Returns rows, columns, project id and modal states
        */
        let rowObj = {};
        for (let data of list.sessions.results) {
            rowObj['customerName'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data.customerName}
            </MDTypography>;
            rowObj['optimileSessionId'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data.optimileSessionId ? data.optimileSessionId : '-'}
            </MDTypography>;
            rowObj['hostPricePerKwh'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data.hostPricePerKwh ? `€${data.hostPricePerKwh}` : '-'}
            </MDTypography>
            rowObj['timeStartedSession'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {Helpers.formatDate(data.timeStartedSession)}
            </MDTypography>
            rowObj['timeStoppedSession'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {Helpers.formatDate(data.timeStoppedSession)}
            </MDTypography>
            rowObj['duration'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {Helpers.dateDifference(data.timeStartedSession, data.timeStoppedSession)}
            </MDTypography>
            rowObj['kwh'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data.kwh ? `${data.kwh} kWH` : '-'}
            </MDTypography>
            rowObj['hostPrice'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data.hostPrice ? `€${data.hostPrice}` : '-'}
            </MDTypography>
            sessionListRow.push(rowObj);
            rowObj = {};
        }
    }

    // Call dashboard API to get data
    /* Fetch Project data [if fetch data changed then only dispatch func will be called] */
    useMemo(() => {
        const fetch = async () => {
            setLoader(true);
            // Call Fetch project dispatch function
            await dispatch(fetchSessions());
            if (loggedRole !== 'customer') {
                await dispatch(fetchCustomer());
            }
            setLoader(false);
        }
        fetch();
    }, [loggedRole]);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Filter />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-2}
                                py={1}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <Grid container>
                                    <Grid item xs={8}>
                                        <MDTypography variant="h6" color="white">
                                            {t('general.Dashboard')}
                                        </MDTypography>
                                    </Grid>
                                </Grid>

                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns: columns, rows: sessionListRow }}
                                    isSorted={true}
                                    entriesPerPage={false}
                                    showTotalEntries={true}
                                    noEndBorder
                                    loader={loader}
                                    pagination={{ count: list?.sessions?.totalPages ? list?.sessions?.totalPages : 5, fetchtype: 'fetchSession' }}
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            {/* <Footer /> */}
        </DashboardLayout>
    );
}

export default Dashboard;
