

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "commonComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commonComponents/Navbars/DashboardNavbar";
import DataTable from "commonComponents/Tables/DataTable";
import { Input, InputAdornment } from "@mui/material";

// Data
import Filter from "./filter";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPriceSetting } from "redux/actions/priceSetting";
import { Icon } from "@mui/material";
import { editPriceSetting } from "redux/actions/priceSetting";
import { toast } from "react-toastify";
import { fetchCustomer } from "redux/actions/customer";
import { fetchInstallers } from "redux/actions/installers";
import { useTranslation } from 'react-i18next';

// price setting
function Pricing() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const list = useSelector(state => state.priceSetting.data);
    const [selectedRow, setSelectedRow] = useState(null);
    const [marginUpdate, setMarginUpdate] = useState(null);
    const loggedRole = JSON.parse(localStorage.getItem('roles'));
    let columns = [];
    let priceSettingListRow = [];
    const handleMarginEdit = (margin, deviceId) => {
        setMarginUpdate(null);
        if (selectedRow !== undefined && deviceId) {
            setSelectedRow(deviceId);
        }
        return;
    }
    const handleChange = (event) => {
        // Get input value from "event"
        setMarginUpdate(event.target.value);
    };
    const handleUpdate = (deviceId) => {
        if (deviceId) {
            let data = {
                margin: marginUpdate,
                optimileDeviceId: deviceId
            }
            // call dispatch func for API
            dispatch(editPriceSetting(data)).then(async (res) => {
                if (res.type === 'success') {
                    setSelectedRow(null);
                    await dispatch(fetchPriceSetting());
                    toast.success(res.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    toast.error(res.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }).catch((e) => {
                toast.error('Something went wrong', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        }
    }

    if (list?.results) {
        columns = [
            { Header: t("general.Customer Name"), accessor: "customerName", align: "left" },
            { Header: t("general.Installer Name"), accessor: "chargePointName", align: "left" },
            { Header: t("general.Selling") + ' ' + t("general.Price"), accessor: "sellingPrice", align: "center" },
            { Header: t("general.Margin"), accessor: "margin", align: "center" },
            { Header: t("general.Amount End User"), accessor: "amountEndUser", align: "center" },
        ]
        /* 
        * Set rows of price setting data array for dataTable list.
        * Returns rows, columns, price setting id and modal states
        */
        let rowObj = {};
        for (let data of list.results) {
            rowObj['customerName'] = <div style={{ width: '150px' }}><MDTypography variant="caption" color="text" fontWeight="medium">
                {data.customerName}
            </MDTypography></div >;
            rowObj['chargePointName'] = <div style={{ width: '150px' }}><MDTypography variant="caption" color="text" fontWeight="medium">
                {data.installerName ? data.installerName : '-'}
            </MDTypography></div>;
            rowObj['sellingPrice'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data.devicePrice ? `€${data.devicePrice}` : '-'}
            </MDTypography>
            rowObj['amountEndUser'] = <MDTypography variant="caption" color="text" fontWeight="medium">
                {data.sellingPrice ? `€${data.sellingPrice}` : '-'}
            </MDTypography>;
            rowObj['margin'] = (
                <>
                    {selectedRow === data.optimileDeviceId ? <>
                        <Input
                            id="standard-adornment-amount"
                            endAdornment={<InputAdornment position="end">%</InputAdornment>}
                            type="number"
                            name="Edit.margin"
                            variant="standard"
                            key={data.margin}
                            value={marginUpdate ? marginUpdate : data.margin}
                            onChange={handleChange}
                        />&nbsp;
                        <MDTypography type="button" variant="body" color="info" onClick={() => handleUpdate(data.optimileDeviceId)}>
                            <Icon>edit</Icon>
                        </MDTypography>&nbsp;
                        <MDTypography type="button" variant="body" color="primary" onClick={() => setSelectedRow(null)}>
                            <Icon>cancel</Icon>
                        </MDTypography>
                    </>
                        : <div style={{ width: '300px' }}>
                            <MDTypography variant="caption" key={data.margin} color="info" fontWeight="medium"
                                onClick={() => handleMarginEdit(data.margin, data.optimileDeviceId)}>
                                {data.margin ? `${data.margin}%` : '-'}
                            </MDTypography>
                        </div>
                    }
                </>)

            priceSettingListRow.push(rowObj);
            rowObj = {};
        }
    }

    // Call dashboard API to get data
    /* Fetch price Setting data [if fetch data changed then only dispatch func will be called] */
    useMemo(() => {
        const fetch = async () => {
            setLoader(true);
            // Call Fetch price setting dispatch function
            await dispatch(fetchPriceSetting());
            if (loggedRole !== 'installer') {
                await dispatch(fetchInstallers());
            }
            await dispatch(fetchCustomer());
            setLoader(false);
        }
        fetch();
    }, [loggedRole]);
    return (<>
        <DashboardLayout>
            <DashboardNavbar />
            <Filter />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={1}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <MDTypography variant="h6" color="white">
                                            {t('general.Charging Stations')}
                                        </MDTypography>
                                    </Grid>
                                </Grid>

                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns: columns, rows: priceSettingListRow }}
                                    isSorted={false}
                                    entriesPerPage={false}
                                    showTotalEntries={true}
                                    noEndBorder
                                    isDownload={false}
                                    loader={loader}
                                    pagination={{ count: list?.totalPages ? list?.totalPages : 5, fetchtype: 'fetchPriceSetting' }}
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            {/* <Footer /> */}
        </DashboardLayout>
    </>
    );
}

export default Pricing;
