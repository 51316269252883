import {
    AUTH,
    LOGOUT
} from "../actions/types";

const initialState = {
    user: [],
    loggedUser: null,
};

function authReducer(loggedUser = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case AUTH:
            if (payload?.data.user) {
                // add logged user in local storage
                localStorage.setItem('loggedUserData', JSON.stringify(payload?.data));
                localStorage.setItem('token', payload?.data?.token);
                localStorage.setItem('roles', JSON.stringify(payload?.data?.user?.role));
            }
            return { ...loggedUser, loggedUser: payload?.data };
        case LOGOUT:
            return { loggedUser: payload };
        default:
            return loggedUser;
    }
}

export default authReducer;