import * as React from 'react';
import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { useState } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import MDTypography from 'components/MDTypography';
import { Grid, Icon } from '@mui/material';
import { DateRange } from 'react-date-range';
import { useFormik } from 'formik';
import { fetchSessions } from 'redux/actions/sessions';
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { useTranslation } from 'react-i18next';
import breakpoints from 'assets/theme/base/breakpoints';
import { useEffect } from 'react';

export default function Filter() {
    const [mobileView, setMobileView] = useState(false);
    const { t } = useTranslation();
    const [isSelect, setIsSelect] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState();
    const dispatch = useDispatch();
    const customerList = useSelector(state => state.customers.data);
    const loggedRole = JSON.parse(localStorage.getItem('roles'));
    let optionList = [];
    if (customerList?.results) {
        for (let data of customerList?.results) {
            if (data.UserToCustomer.customerId) {
                optionList.push(
                    { value: data.userId, label: data.UserToCustomer.firstName + ' ' + data.UserToCustomer.lastName }
                )
            }
        }
    }
    const handleSelect = (data) => {
        setSelectedOptions(data);
    }
    const togglePersonHandler = (event) => {
        event.preventDefault();
        if (isSelect) {
            setIsSelect(false);
        } else {
            setIsSelect(true);
        }
    }
    const [state, setState] = useState([
        {
            key: 'selection'
        }
    ]);
    const formik = useFormik({
        initialValues: {
            date: "",
            customerId: ""
        },
        onSubmit: async (values) => {
            setIsSelect(false);
            if (state[0]?.startDate || state[0]?.endDate) {
                formik.values.date = state;
            }
            if (selectedOptions?.value) {
                formik.values.customerId = selectedOptions.value;
            }
            if (state[0]?.startDate || state[0]?.endDate || selectedOptions?.value) {
                await dispatch(fetchSessions(null, { customerId: selectedOptions?.value, startPeriod: state[0]?.startDate, endPeriod: state[0]?.endDate }));
            }
        }
    });
    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: "transparent",
            fontSize: 17,
            borderRadius: state.isFocused ? "3px 3px 0 0" : 7,
            boxShadow: state.isFocused ? null : null,
        })
    };
    const resetData = async () => {
        setState([{
            key: 'selection'
        }]);
        setIsSelect(false);
        setSelectedOptions('');
        await dispatch(fetchSessions(null, null));
    }
    useEffect(() => {
        // A function that sets the display state for the DefaultFormMobile.
        function displayMobileView() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true);
            } else {
                setMobileView(false);
            }
        }
        /** 
         The event listener that's calling the displayMobileView function when 
         resizing the window.
        */
        window.addEventListener("resize", displayMobileView);

        // Call the displayMobileView function to set the state with the initial value.
        displayMobileView();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", displayMobileView);
    }, []);
    return (
        <Grid>
            <MDBox component="form" role="form" m={1}
                display="flex"
                justifyContent="flex-end"
                onSubmit={formik.handleSubmit}
            >
                <Grid>
                    {loggedRole !== 'customer' && <FormControl style={{
                        'width': '300px',
                    }}>
                        <Select
                            styles={customStyles}
                            options={optionList}
                            placeholder={t('general.Select') + ' ' + t('general.Customer Name')}
                            value={selectedOptions}
                            onChange={handleSelect}
                            isSearchable={true}
                            autoWidth={false}
                        />
                    </FormControl>}&nbsp;
                    <MDTypography type="button" variant="body" p={mobileView && 2} fontWeight="bold" color="info" mt={1}
                        onClick={togglePersonHandler}>
                        <Icon>calendar_month</Icon>
                    </MDTypography>
                    {mobileView ? <br /> : ' '}
                    <MDButton mt={10} variant="gradient" color="info" type="submit">
                        {t('action.Search')}
                    </MDButton>&nbsp;
                    <MDButton variant="gradient" type="reset" color="primary" onClick={resetData}>
                        {t('action.Reset')}
                    </MDButton>
                </Grid>
            </MDBox>
            {
                isSelect && <MDBox display="flex"
                    justifyContent="flex-end" style={{ "position": "absolute", "right": "30px", "zIndex": "99" }}>
                    <DateRange
                        editableDateInputs={true}
                        value={formik.values.date}
                        onChange={item => setState([item.selection])}
                        moveRangeOnFirstSelection={false}
                        ranges={state}
                        maxDate={new Date()}
                        direction="vertical"
                        scroll={{ enabled: true }}
                    /></MDBox>
            }
        </Grid>
    );
}