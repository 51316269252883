// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "commonComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commonComponents/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import { Card } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { fetchInstallerForCustomer } from "redux/actions/customer";
import { useDispatch, useSelector } from "react-redux";
import breakpoints from "assets/theme/base/breakpoints";
function Contact() {
    const [mobileView, setMobileView] = useState(false);
    const data = useSelector(state => state?.customers?.customerInstallerData?.installer);
    const dispatch = useDispatch();
    useMemo(() => {
        const fetch = async () => {
            await dispatch(fetchInstallerForCustomer());
        }
        fetch();
    }, []);
    useEffect(() => {
        // A function that sets the display state for the DefaultFormMobile.
        function displayMobileView() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true);
            } else {
                setMobileView(false);
            }
        }
        /** 
         The event listener that's calling the displayMobileView function when 
         resizing the window.
        */
        window.addEventListener("resize", displayMobileView);

        // Call the displayMobileView function to set the state with the initial value.
        displayMobileView();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", displayMobileView);
    }, []);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <MDTypography variant="h6" color="white">
                                            Installer Information
                                        </MDTypography>
                                    </Grid>
                                </Grid>

                            </MDBox>
                            <Grid item xs={mobileView ? 12 : 6}>
                                <MDBox pt={4} pb={3} px={3}>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <span style={{ fontSize: '15px' }}>First Name</span>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <span style={{ fontSize: '15px' }}> : </span>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <MDBox mb={2}>
                                                <span style={{ fontSize: '15px' }}>{data?.Installer?.firstName ? data?.Installer?.firstName : '-'}</span>
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <span style={{ fontSize: '15px' }}>Last Name</span>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <span style={{ fontSize: '15px' }}> : </span>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <MDBox mb={2}>
                                                <span style={{ fontSize: '15px' }}>{data?.Installer?.lastName ? data?.Installer?.lastName : '-'}</span>
                                            </MDBox>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <span style={{ fontSize: '15px' }}>Email</span>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <span style={{ fontSize: '15px' }}> : </span>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <MDBox mb={2}>
                                                <span style={{ fontSize: '15px' }}>{data?.email}</span>
                                            </MDBox>
                                        </Grid>
                                    </Grid>
                                </MDBox>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default Contact;