import {
    INSTALLERSDATA
} from "../actions/types";
import axios from "axios";
import Service from "service";
import Helpers from "helpers";
// Actions
export const fetchInstallers = (page, installerId, activeStatus, csv) => async dispatch => {
    try {
        const loggedRole = JSON.parse(localStorage.getItem('roles'));
        let params = {
            type: 'Installer',
            active: 1
        };
        if (activeStatus === 0 || activeStatus === 1) {
            params['active'] = activeStatus;
        }
        if (page) {
            params['page'] = page;
        }
        if (installerId) {
            params['userId'] = installerId;
            params['active'] = activeStatus;
        }
        if (loggedRole === 'installer') {
            let id = JSON.parse(localStorage.getItem('loggedUserData'));
            params['userId'] = id?.user?.userId;
        }
        if (csv) {
            params['csv'] = true;
        }
        // Calling Installer API
        const res = await axios.get(`${process.env.REACT_APP_BaseUrl}/api/v1/installers/list`, Service.config(params));
        if (res.data && csv) {
            Helpers.csvDownload(res.data, res.headers);
            return;
        }
        if (res.data) {
            dispatch({
                type: INSTALLERSDATA,
                payload: {
                    installersResponse: res.data.data,
                    params: params
                }
            });
            return Promise.resolve(res.data.data);
        }
    } catch (e) {
        return Promise.reject(e);
    }
}

export const addInstaller = (installerData) => async dispatch => {
    try {
        // Calling add installer API
        const res = await axios.post(`${process.env.REACT_APP_BaseUrl}/api/v1/users/registration`, installerData, Service.config());
        if (res.data) {
            return Promise.resolve(res.data);
        }
    } catch (e) {
        return Promise.reject(e);
    }
}
export const editInstaller = (installerData, installerId) => async dispatch => {
    try {
        // Calling edit installer API
        const res = await axios.put(`${process.env.REACT_APP_BaseUrl}/api/v1/installers/update/${installerId}`, installerData, Service.config());
        if (res.data) {
            return Promise.resolve(res.data);
        }
    } catch (e) {
        return Promise.reject(e);
    }
}