import {
    OVERVIEWDATA
} from "../actions/types";

const initialState = {
    paramsData: null,
    data: null
};
function overviewReducer(overviewsData = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case OVERVIEWDATA:
            // add overview data in state
            return { ...overviewsData, data: payload?.overviewResponse, paramsData: { ...overviewsData?.paramsData?.params, ...payload.params } };
        default:
            return overviewsData;
    }
}

export default overviewReducer;  