
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/background.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Alert } from "@mui/material";
import { resetPassword } from "redux/actions/auth";

function ResetPassword() {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const search = useLocation().search;
    const token = new URLSearchParams(search).get("token");
    const reset = new URLSearchParams(search).get("reset");
    const formik = useFormik({
        initialValues: {
            Reset: {
                password: "",
                confirmPassword: ""
            }
        },
        validationSchema: Yup.object({
            Reset: Yup.object({
                password: Yup.string().required("New Password is required"),
                confirmPassword: Yup.string().when("password", {
                    is: val => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("password")],
                        "Both password need to be the same"
                    )
                })
            })
        }),
        onSubmit: async (values) => {
            if (values?.Reset) {
                let data = {
                    password: values?.Reset?.password,
                    token: token
                }
                dispatch(resetPassword(data)).then(async (res) => {
                    if (res.type === 'success') {
                        Navigate('/authentication/sign-in');
                        toast.success(res.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        toast.error(res.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }).catch((e) => {
                    toast.error('Something went wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            }
        }
    });
    return (
        <CoverLayout coverHeight="50vh" image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-3}
                    py={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
                    {reset === 'true' ? 'Reset Password' : 'Set Password'}
                    </MDTypography>
                    <MDTypography display="block" variant="button" color="white" my={1}>
                        You will receive an e-mail in maximum 3 hours
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
                        <MDBox mb={4}>
                            <MDInput variant="standard"
                                type="password" label="New Password"
                                name="Reset.password"
                                value={formik.values.Reset.password}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                fullWidth />
                            {formik.errors.Reset?.password && formik.touched.Reset?.password && (
                                <Alert severity="error" sx={{
                                    height: 50
                                }}><small>{formik.errors.Reset.password}</small></Alert>
                            )}
                        </MDBox>
                        <MDBox mb={4}>
                            <MDInput variant="standard"
                                type="password" label="Confirm Password"
                                name="Reset.confirmPassword"
                                value={formik.values.Reset.confirmPassword}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                fullWidth />
                            {formik.errors.Reset?.confirmPassword && formik.touched.Reset?.confirmPassword && (
                                <Alert severity="error" sx={{
                                    height: 50
                                }}><small>{formik.errors.Reset.confirmPassword}</small></Alert>
                            )}
                        </MDBox>
                        <MDBox mt={6} mb={1}>
                            <MDButton variant="gradient" color="info" type="submit" fullWidth>
                                Reset
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Card>
        </CoverLayout>
    );
}

export default ResetPassword;
