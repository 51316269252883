// Images
import bgImage from "assets/images/background.png";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchInstallationReportConfirmation } from "redux/actions/customer";
const alertContent = (message) => (
    <MDTypography variant="body2" color="white">
        {message}
    </MDTypography>
);
function ReportConfirmation() {
    const search = useLocation().search;
    const dispatch = useDispatch();
    const customerUserId = new URLSearchParams(search).get("customerUserId");
    const response = useSelector(state => state.customers.reportResponse);
    /* Fetch customer installation report response data [if fetch data changed then only dispatch func will be called] */
    useMemo(() => {
        const fetch = async () => {
            // Call Fetch installation report confirmation dispatch function
            await dispatch(fetchInstallationReportConfirmation(customerUserId));
        }
        fetch();
    }, [customerUserId]);
    return (
        <BasicLayout image={bgImage} len={3}>
            {response && response.type && <MDAlert color={response.type}>
                {alertContent(response.message)}
            </MDAlert>
            }
        </BasicLayout>
    );
}

export default ReportConfirmation;
