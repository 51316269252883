import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { Alert } from "@mui/material";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "commonComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commonComponents/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { resetPassword } from "redux/actions/auth";
import { logout } from "redux/actions/auth";
import Helpers from "helpers";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import breakpoints from "assets/theme/base/breakpoints";

function SetPassword() {
    const [mobileView, setMobileView] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const loggedUser = JSON.parse(localStorage.getItem('loggedUserData'));
    // validation
    const formik = useFormik({
        initialValues: {
            Reset: {
                oldPassword: "",
                password: "",
                confirmPassword: ""
            }
        },
        validationSchema: Yup.object({
            Reset: Yup.object({
                oldPassword: Yup.string().required("Current Password is required"),
                password: Yup.string().required("New Password is required"),
                confirmPassword: Yup.string().when("password", {
                    is: val => (val && val.length > 0 ? true : false),
                    then: Yup.string().oneOf(
                        [Yup.ref("password")],
                        "Both password need to be the same"
                    )
                })
            })
        }),
        onSubmit: async (values) => {
            if (values?.Reset) {
                let data = {
                    oldPassword: values?.Reset?.oldPassword,
                    password: values?.Reset?.password
                }
                dispatch(resetPassword(data)).then(async (res) => {
                    if (res.type === 'success') {
                        toast.success(res.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        dispatch(logout());
                        window.location.reload(false);
                    } else {
                        toast.error(res.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }).catch((e) => {
                    toast.error('Something went wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            }
        }
    });
    useEffect(() => {
        // A function that sets the display state for the DefaultFormMobile.
        function displayMobileView() {
            if (window.innerWidth < breakpoints.values.lg) {
                setMobileView(true);
            } else {
                setMobileView(false);
            }
        }
        /** 
         The event listener that's calling the displayMobileView function when 
         resizing the window.
        */
        window.addEventListener("resize", displayMobileView);

        // Call the displayMobileView function to set the state with the initial value.
        displayMobileView();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", displayMobileView);
    }, []);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={1}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <MDTypography variant="h6" color="white">
                                            {t('general.Change Password')}
                                        </MDTypography>
                                    </Grid>
                                    <Grid item xs={mobileView ? 12 : 4}>
                                        {loggedUser?.user?.updatedAt &&
                                            <MDTypography style={{ float: 'right' }} variant="h6" color="light">
                                                {t("general.Last Updated On")} : {Helpers.formatDate(loggedUser?.user?.updatedAt)}
                                            </MDTypography>
                                        }
                                    </Grid>
                                </Grid>

                            </MDBox>
                            <Grid item xs={mobileView ? 12 : 6}>
                                <MDBox pt={4} pb={3} px={3}>
                                    <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
                                        <MDBox mb={2}>
                                            <MDInput type="password" label={t("general.Current Password")}
                                                name="Reset.oldPassword"
                                                value={formik.values.Reset.oldPassword}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                fullWidth
                                            />
                                            {formik.errors.Reset?.oldPassword && formik.touched.Reset?.oldPassword && (
                                                <Alert severity="error" sx={{
                                                    height: 50
                                                }}><small>{t(`validation.${formik.errors.Reset.oldPassword}`)}</small></Alert>
                                            )}
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput type="password" label={t("general.New Password")}
                                                name="Reset.password"
                                                value={formik.values.Reset.password}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                fullWidth
                                            />
                                            {formik.errors.Reset?.password && formik.touched.Reset?.password && (
                                                <Alert severity="error" sx={{
                                                    height: 50
                                                }}><small>{t(`validation.${formik.errors.Reset.password}`)}</small></Alert>
                                            )}
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput type="password" label={t("general.Confirm Password")}
                                                name="Reset.confirmPassword"
                                                value={formik.values.Reset.confirmPassword}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                fullWidth
                                            />
                                            {formik.errors.Reset?.confirmPassword && formik.touched.Reset?.confirmPassword && (
                                                <Alert severity="error" sx={{
                                                    height: 50
                                                }}><small>{t(`validation.${formik.errors.Reset.confirmPassword}`)}</small></Alert>
                                            )}
                                        </MDBox>
                                        <MDBox mt={4} mb={1}>
                                            <MDButton variant="gradient" color="info" type="submit">
                                                {t('action.Reset')}
                                            </MDButton>&nbsp;
                                            <MDButton variant="gradient" color="primary" onClick={() => Navigate('/dashboard')}>
                                                {t('action.Cancel')}
                                            </MDButton>
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            {/* <Footer /> */}
        </DashboardLayout>
    );
}

export default SetPassword;
