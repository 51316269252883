import { useEffect, useMemo } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import SidenavCollapse from "commonComponents/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "commonComponents/Sidenav/SidenavRoot";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import { useDispatch, useSelector } from "react-redux";
import { fetchInstallers } from "redux/actions/installers";
import { fetchInstallerForCustomer } from "redux/actions/customer";
import { useTranslation } from 'react-i18next';

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const dispatchFn = useDispatch();
  const collapseName = location.pathname.replace("/", "");
  const collapseNameSplit = location.pathname.replace("/", "").split('/')[1]; // get key fro create and edit modules for manage active class
  const data = useSelector(state => state?.installers?.data);
  const customerData = useSelector(state => state?.customers?.customerInstallerData?.installer);
  let loggedUserData = JSON.parse(localStorage.getItem('loggedUserData'));
  const { t } = useTranslation();
  let textColor = "white";
  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }
  let image = "/whiteicon.png";
  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    image = '/blackicon.png';
  }
  if (loggedUserData?.user.role === 'installer') {
    image = data?.results[0]?.Installer?.logoUrl;
  }
  if (loggedUserData?.user.role === 'customer') {
    image = customerData?.Installer?.logoUrl;
  }
  const closeSidenav = () => setMiniSidenav(dispatch, true);
  useMemo(() => {
    const fetch = async () => {
      if (loggedUserData?.user.role === 'installer') {
        // Call Fetch project dispatch function
        await dispatchFn(fetchInstallers(null, loggedUserData?.user?.userId));
      }
      if (loggedUserData?.user.role === 'customer') {
        await dispatchFn(fetchInstallerForCustomer());
      }
    }
    fetch();
  }, []);
  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route }) => {
    let returnValue;
    if (type === "collapse") {
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={key === collapseName}
            noCollapse={noCollapse}
          />
        </Link>
      ) : name ? (
        <NavLink key={key} to={route}>
          <SidenavCollapse name={t(`sidenav.${name}`)} icon={icon} active={key === collapseName || key === collapseNameSplit} />
        </NavLink>
      ) : '';
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={2} pb={1} px={3} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" alignItems="center">
          {brand && <MDBox component="img" src={image} alt="Brand" width="8rem" />}
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
