import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Backdrop, CircularProgress, FormControl, InputAdornment, InputLabel, OutlinedInput, Switch } from "@mui/material";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "commonComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "commonComponents/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addCustomer } from "redux/actions/customer";
import { fetchCustomer } from "redux/actions/customer";
import { PickerOverlay } from 'filestack-react';
import { useEffect, useMemo, useState } from "react";
import { fetchInstallers } from "redux/actions/installers";
import Select from "react-select";
import QrReader from 'react-qr-reader';
import breakpoints from "assets/theme/base/breakpoints";
import { useTranslation } from 'react-i18next';

function AddCustomer() {
    const FILESTACK_KEY = process.env.REACT_APP_FILESTACK_API_KEY;
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const [mobileView, setMobileView] = useState(false);
    const [loader, setLoader] = useState(false);
    const loggedRole = JSON.parse(localStorage.getItem('roles'));
    let id = JSON.parse(localStorage.getItem('loggedUserData'));
    const logInstallerId = id?.user?.userId;
    const installerList = useSelector(state => state.installers.data);
    const [uploadCircuitBreaker, setCircuitBreaker] = useState(false);
    const [uploadGroupBox, setGroupBox] = useState(false);
    const [uploadCableCharging, setCableCharging] = useState(false);
    const [uploadFrontCharging, setFrontCharging] = useState(false);
    const [uploadLabelCharging, setLabelCharging] = useState(false);
    const [selectedInstallerOptions, setSelectedInstallerOptions] = useState('');
    // Manage state to show error and uploaded image
    const [circuitBreakerUrl, setCircuitBreakerUrl] = useState('');
    const [groupBoxUrl, setGroupBoxUrl] = useState('');
    const [cableChargingUrl, setCableChargingUrl] = useState('');
    const [frontChargingUrl, setFrontChargingUrl] = useState('');
    const [labelChargingUrl, setLabelChargingUrl] = useState('');
    const [scanQrSerialNumber, setScanQrSerialNumber] = useState('');
    const [showWebcam, setShowWebcam] = useState(false);
    const { t } = useTranslation();
    useMemo(() => {
        const fetch = async () => {
            await dispatch(fetchInstallers());
        }
        fetch();
    }, []);
    let optionInstallerList = [];
    if (installerList?.results) {
        for (let data of installerList?.results) {
            if (data.userId) {
                optionInstallerList.push(
                    { value: data.userId, label: data?.Installer?.firstName + ' ' + data?.Installer?.lastName }
                )
            }
        }
    }
    // validation
    const formik = useFormik({
        initialValues: {
            Add: {
                installerId: loggedRole === 'installer' ? logInstallerId : "",
                firstName: "",
                lastName: "",
                email: "",
                address: "",
                postcode: "",
                city: "",
                // country: "",
                serialNumber: "",
                photoCircuitBreaker: "",
                photoGroupBox: "",
                photoCableChargingDevice: "",
                photoFrontChargingDevice: "",
                photoLabelChargingDevice: "",
                stationName: "",
                cableThickness: "",
                connectionValue: "",
                public: false, //false in dutch
                homeCharger: false, //false in dutch
                power: ""
            }
        },
        validationSchema: Yup.object({
            Add: Yup.object({
                installerId: Yup.string().when({
                    is: () => formik.initialValues.Add.installerId === '',
                    then: Yup.string().required('Installer is required'),
                    otherwise: Yup.string(),
                }),
                firstName: Yup.string().required("First Name is required"),
                lastName: Yup.string().required("Last Name is required"),
                email: Yup.string().required("Email is required"),
                address: Yup.string().required("Address is required"),
                postcode: Yup.string().required("Postal Code is required"),
                city: Yup.string().required("City is required"),
                // country: Yup.string().required("Country is required"),
                serialNumber: Yup.string().required("Serial Number is required"),
                photoCircuitBreaker: Yup.string().when({
                    is: () => formik.initialValues.Add.photoCircuitBreaker === '',
                    then: Yup.string().required('Circuit Breaker is required'),
                    otherwise: Yup.string(),
                }),
                photoGroupBox: Yup.string().when({
                    is: () => formik.initialValues.Add.photoGroupBox === '',
                    then: Yup.string().required('Group Box is required'),
                    otherwise: Yup.string(),
                }),
                photoCableChargingDevice: Yup.string().when({
                    is: () => formik.initialValues.Add.photoCableChargingDevice === '',
                    then: Yup.string().required('Cable Charging is required'),
                    otherwise: Yup.string(),
                }),
                photoFrontChargingDevice: Yup.string().when({
                    is: () => formik.initialValues.Add.photoFrontChargingDevice === '',
                    then: Yup.string().required('Front Charging is required'),
                    otherwise: Yup.string(),
                }),
                photoLabelChargingDevice: Yup.string().when({
                    is: () => formik.initialValues.Add.photoLabelChargingDevice === '',
                    then: Yup.string().required('Label Charging is required'),
                    otherwise: Yup.string(),
                }),
                stationName: Yup.string().required("Station Name is required"),
                cableThickness: Yup.string().required("Cable Thickness is required"),
                connectionValue: Yup.string().required("Connection Value is required"),
                public: Yup.mixed().required("Public is required"),
                homeCharger: Yup.mixed().required("Home Charger is required"),
                power: Yup.string().required("Power Connection is required"),
            })
        }),
        onSubmit: async (values) => {
            if (values?.Add) {
                setLoader(true);
                let data = values.Add;
                if (formik.initialValues.Add) {
                    data.installerId = formik.initialValues.Add.installerId;
                    data.photoCircuitBreaker = formik.initialValues.Add.photoCircuitBreaker;
                    data.photoGroupBox = formik.initialValues.Add.photoGroupBox;
                    data.photoCableChargingDevice = formik.initialValues.Add.photoCableChargingDevice;
                    data.photoFrontChargingDevice = formik.initialValues.Add.photoFrontChargingDevice;
                    data.photoLabelChargingDevice = formik.initialValues.Add.photoLabelChargingDevice;
                    // Sending Ja for true and Nee for false
                    data.homeCharger = values.Add.homeCharger ? 'Ja' : 'Nee';
                    data.public = values.Add.public ? 'Ja' : 'Nee';
                    data.cableThickness = values.Add.cableThickness && values.Add.cableThickness;
                    data.connectionValue = values.Add.connectionValue && values.Add.connectionValue;
                    data.power = values.Add.power === (values.Add.power + 'kwh') ? values.Add.power : values.Add.power + 'kwh';
                }
                dispatch(addCustomer(data)).then(async (res) => {
                    setLoader(false);
                    if (res.type === 'success') {
                        await dispatch(fetchCustomer());
                        Navigate('/customers');
                        toast.success(res.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        toast.error(res.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }).catch((e) => {
                    setLoader(false);
                    toast.error('Something went wrong', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            }
        }
    });
    // --------------------------------------------------------------------------
    // On browse click handle filestack picker
    const handleFileStackCircuitBreaker = () => {
        setCircuitBreaker(true);
    }
    const handleFileStackGroupBox = () => {
        setGroupBox(true);
    }
    const handleFileStackCableCharging = () => {
        setCableCharging(true);
    }
    const handleFileStackFrontCharging = () => {
        setFrontCharging(true);
    }
    const handleFileStackLabelCharging = () => {
        setLabelCharging(true);
    }
    // --------------------------------------------------------------------------
    // On success/fail of file upload handle formik values of all photos for submiting form values
    const handlephotoCircuitBreakerUpload = (res) => {
        if (res.filesUploaded) {
            setCircuitBreakerUrl(res.filesUploaded[0]?.url);
            formik.initialValues.Add.photoCircuitBreaker = res.filesUploaded[0]?.url;
            if (formik.errors?.Add) {
                formik.errors.Add.photoCircuitBreaker = '';
            }
        }
        setCircuitBreaker(false);
    }
    const handlePhotoGroupBoxUpload = (res) => {
        if (res?.filesUploaded) {
            setGroupBoxUrl(res.filesUploaded[0]?.url);
            formik.initialValues.Add.photoGroupBox = res.filesUploaded[0]?.url;
            if (formik.errors?.Add) {
                formik.errors.Add.photoGroupBox = '';
            }
        }
        setGroupBox(false);
    }
    const handlePhotoCableChargingDeviceUpload = (res) => {
        if (res.filesUploaded) {
            setCableChargingUrl(res.filesUploaded[0]?.url);
            formik.initialValues.Add.photoCableChargingDevice = res.filesUploaded[0]?.url;
            if (formik.errors?.Add) {
                formik.errors.Add.photoCableChargingDevice = '';
            }
        }
        setCableCharging(false);
    }
    const handlePhotoFrontChargingDeviceUpload = (res) => {
        if (res.filesUploaded) {
            setFrontChargingUrl(res.filesUploaded[0]?.url);
            formik.initialValues.Add.photoFrontChargingDevice = res.filesUploaded[0]?.url;
            if (formik.errors?.Add) {
                formik.errors.Add.photoFrontChargingDevice = '';
            }
        }
        setFrontCharging(false);
    }
    const handlePhotoLabelChargingDeviceUpload = (res) => {
        if (res.filesUploaded) {
            setLabelChargingUrl(res.filesUploaded[0]?.url);
            formik.initialValues.Add.photoLabelChargingDevice = res.filesUploaded[0]?.url;
            if (formik.errors?.Add) {
                formik.errors.Add.photoLabelChargingDevice = '';
            }
        }
        setLabelCharging(false);
    }
    const handleInstallerSelect = (data) => {
        setSelectedInstallerOptions(data);
        formik.initialValues.Add.installerId = data?.value;
        if (formik.errors?.Add) {
            formik.errors.Add.installerId = '';
        }
    }
    const handleScanWebCam = (result) => {
        if (result) {
            setScanQrSerialNumber(result);
            // formik.initialValues.Add.serialNumber = result;
            formik.values.Add.serialNumber = result;
            setShowWebcam(false);
            if (formik.errors?.Add) {
                formik.errors.Add.serialNumber = '';
            }
        }
    }
    const handleErrorWebCam = (error) => {
        console.log(error);
        toast.warning(error?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    const onScanFile = () => {
        setShowWebcam(true);
    }
    useEffect(() => {
        setCircuitBreaker(false);
        setGroupBox(false);
        setCableCharging(false);
        setFrontCharging(false);
        setLabelCharging(false);
    }, [window.scrollY]);
    useEffect(() => {
        // A function that sets the display state for the DefaultFormMobile.
        function displayMobileView() {
            if (window.innerWidth < breakpoints.values.lg) {
                setShowWebcam(false);
                setMobileView(true);
            } else {
                setShowWebcam(false);
                setMobileView(false);
            }
        }
        /** 
         The event listener that's calling the displayMobileView function when 
         resizing the window.
        */
        window.addEventListener("resize", displayMobileView);

        // Call the displayMobileView function to set the state with the initial value.
        displayMobileView();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", displayMobileView);
    }, []);
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                {loader && <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loader}
                >
                    <CircularProgress />
                </Backdrop>}
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={1}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <MDTypography variant="h6" color="white">
                                            {t('general.Create Customer')}
                                        </MDTypography>
                                    </Grid>
                                </Grid>

                            </MDBox>
                            <Grid item xs={12}>
                                <MDBox pt={4} pb={3} px={3}>
                                    <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
                                        <MDTypography variant="h5" mb={2}>
                                            {t("general.User")} {t("general.Information")}
                                        </MDTypography>
                                        <Grid container spacing={0}>
                                            <Grid item xs={4}>
                                                <span style={{ fontSize: '15px' }}>{t("customAction.Scan QR Code")} </span>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <MDBox mb={2}>
                                                    <MDButton variant="contained" style={{ marginBottom: '20px' }} color="secondary" onClick={onScanFile}>{t('customAction.Scan QR Code')}</MDButton>
                                                    {
                                                        showWebcam ?
                                                            mobileView ?
                                                                <QrReader
                                                                    delay={300}
                                                                    style={{ width: '100%' }}
                                                                    onError={handleErrorWebCam}
                                                                    onScan={handleScanWebCam}
                                                                /> : <QrReader
                                                                    delay={300}
                                                                    style={{ width: '40%' }}
                                                                    onError={handleErrorWebCam}
                                                                    onScan={handleScanWebCam}
                                                                /> : ''
                                                    }
                                                </MDBox>
                                            </Grid>
                                            {loggedRole === 'admin' && <>
                                                <Grid item xs={4}>
                                                    <span style={{ fontSize: '15px' }}>{t("general.Select Installer")}</span>
                                                </Grid>
                                                <Grid item xs={8}
                                                    style={{ 'zIndex': 99 }}>
                                                    <MDBox mb={2}>
                                                        <Select
                                                            // styles={customStyles}
                                                            options={optionInstallerList}
                                                            placeholder={t('general.Select') + ' ' + t('general.Installer Name')}
                                                            value={selectedInstallerOptions}
                                                            onChange={handleInstallerSelect}
                                                            isSearchable={true}
                                                            autoWidth={false}
                                                        />
                                                        {formik.errors.Add?.installerId && formik.touched.Add?.installerId && (
                                                            <Alert severity="error"><small>{t(`validation.${formik.errors.Add.installerId}`)}</small></Alert>
                                                        )}
                                                    </MDBox>
                                                </Grid>
                                            </>}
                                            <Grid item xs={4}>
                                                <span style={{ fontSize: '15px' }}>{t("general.First Name")}</span>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <MDBox mb={2}>
                                                    <MDInput type="text" label={t("general.First Name")}
                                                        name="Add.firstName"
                                                        value={formik.values.Add.firstName}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        fullWidth
                                                    />
                                                    {formik.errors.Add?.firstName && formik.touched.Add?.firstName && (
                                                        <Alert severity="error"><small>{t(`validation.${formik.errors.Add.firstName}`)}</small></Alert>
                                                    )}
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <span style={{ fontSize: '15px' }}>{t("general.Last Name")}</span>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <MDBox mb={2}>
                                                    <MDInput type="text" label={t("general.Last Name")}
                                                        name="Add.lastName"
                                                        value={formik.values.Add.lastName}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        fullWidth
                                                    />
                                                    {formik.errors.Add?.lastName && formik.touched.Add?.lastName && (
                                                        <Alert severity="error"><small>{t(`validation.${formik.errors.Add.lastName}`)}</small></Alert>
                                                    )}
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <span style={{ fontSize: '15px' }}>{t("general.Address")}</span>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <MDBox mb={2}>
                                                    <MDInput type="text" label={t("general.Address")}
                                                        name="Add.address"
                                                        value={formik.values.Add.address}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        fullWidth
                                                    />
                                                    {formik.errors.Add?.address && formik.touched.Add?.address && (
                                                        <Alert severity="error"><small>{t(`validation.${formik.errors.Add.address}`)}</small></Alert>
                                                    )}
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <span style={{ fontSize: '15px' }}>{t("general.Postal Code")}</span>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <MDBox mb={2}>
                                                    <MDInput type="text" label={t("general.Postal Code")}
                                                        name="Add.postcode"
                                                        value={formik.values.Add.postcode}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        fullWidth
                                                    />
                                                    {formik.errors.Add?.postcode && formik.touched.Add?.postcode && (
                                                        <Alert severity="error"><small>{t(`validation.${formik.errors.Add.postcode}`)}</small></Alert>
                                                    )}
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <span style={{ fontSize: '15px' }}>{t("general.City")}</span>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <MDBox mb={2}>
                                                    <MDInput type="text" label={t("general.City")}
                                                        name="Add.city"
                                                        value={formik.values.Add.city}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        fullWidth
                                                    />
                                                    {formik.errors.Add?.city && formik.touched.Add?.city && (
                                                        <Alert severity="error"><small>{t(`validation.${formik.errors.Add.city}`)}</small></Alert>
                                                    )}
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={4}>
                                                {/* <span style={{ fontSize: '15px' }}>Country</span> */}
                                            </Grid>
                                            <Grid item xs={8}>
                                                <MDBox mb={2}>
                                                    {/* <MDInput type="text" label="country"
                                                        name="Add.country"
                                                        fullWidth
                                                    /> */}
                                                    {formik.errors.Add?.country && formik.touched.Add?.country && (
                                                        <Alert severity="error"><small>{t(`validation.${formik.errors.Add.country}`)}</small></Alert>
                                                    )}
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <span style={{ fontSize: '15px' }}>{t("general.Email")}</span>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <MDBox mb={2}>
                                                    <MDInput type="email" label={t("general.Email")}
                                                        name="Add.email"
                                                        value={formik.values.Add.email}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        fullWidth
                                                    />
                                                    {formik.errors.Add?.email && formik.touched.Add?.email && (
                                                        <Alert severity="error"><small>{t(`validation.${formik.errors.Add.email}`)}</small></Alert>
                                                    )}
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <span style={{ fontSize: '15px' }}>{t("general.Serial Number")}</span>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <MDBox mb={2}>
                                                    <MDInput type="text" label={t("general.Serial Number")}
                                                        disabled={scanQrSerialNumber && true}
                                                        name="Add.serialNumber"
                                                        value={formik.values.Add.serialNumber ? formik.values.Add.serialNumber : scanQrSerialNumber}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        fullWidth
                                                    />
                                                    {formik.errors.Add?.serialNumber && formik.touched.Add?.serialNumber && (
                                                        <Alert severity="error"><small>{t(`validation.${formik.errors.Add.serialNumber}`)}</small></Alert>
                                                    )}
                                                </MDBox>
                                            </Grid>
                                            {/* ----------------------------------------------------------- */}
                                            <Grid item xs={12}>
                                                <MDTypography variant="h5" mb={2}>
                                                    {t("general.Basic Details")}
                                                </MDTypography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <span style={{ fontSize: '15px' }}>{t("general.Charging Station Name")}</span>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <MDBox mb={2}>
                                                    <MDInput type="text" label={t("general.Station Name")}
                                                        name="Add.stationName"
                                                        value={formik.values.Add.stationName}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                        fullWidth
                                                    />
                                                    {formik.errors.Add?.stationName && formik.touched.Add?.stationName && (
                                                        <Alert severity="error"><small>{t(`validation.${formik.errors.Add.stationName}`)}</small></Alert>
                                                    )}
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <span style={{ fontSize: '15px' }}>{t("general.Power Connection")}</span>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <MDBox mb={2}>
                                                    <FormControl mb={2} sx={{ width: '100%' }} variant="outlined">
                                                        <InputLabel htmlFor="outlined-adornment-thickness" style={{ fontSize: mobileView ? '13px' : '' }}>{t("general.Power Connection")}</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-thickness"
                                                            type='text'
                                                            endAdornment={
                                                                <InputAdornment position="end">kwh
                                                                </InputAdornment>
                                                            }
                                                            label={t("general.Power Connection")}
                                                            name="Add.power"
                                                            value={formik.values.Add.power}
                                                            onBlur={formik.handleBlur}
                                                            onChange={formik.handleChange}
                                                            fullWidth
                                                        />
                                                        {formik.errors.Add?.power && formik.touched.Add?.power && (
                                                            <Alert severity="error"><small>{t(`validation.${formik?.errors?.Add?.power}`)}</small></Alert>
                                                        )}
                                                    </FormControl>
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <span style={{ fontSize: '15px' }}>{t("general.Cable Thickness")}</span>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <MDBox mb={2}>
                                                    <FormControl mb={2} sx={{ width: '100%' }} variant="outlined">
                                                        <InputLabel htmlFor="outlined-adornment-thickness" style={{ fontSize: mobileView ? '13px' : '' }}>{t("general.Cable Thickness")}</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-thickness"
                                                            type='text'
                                                            endAdornment={
                                                                <InputAdornment position="end">mm2
                                                                </InputAdornment>
                                                            }
                                                            label={t("general.Cable Thickness")}
                                                            name="Add.cableThickness"
                                                            value={formik.values.Add.cableThickness}
                                                            onBlur={formik.handleBlur}
                                                            onChange={formik.handleChange}
                                                            fullWidth
                                                        />
                                                        {formik.errors.Add?.cableThickness && formik.touched.Add?.cableThickness && (
                                                            <Alert severity="error"><small>{t(`validation.${formik.errors.Add.cableThickness}`)}</small></Alert>
                                                        )}
                                                    </FormControl>
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <span style={{ fontSize: '15px' }}>{t("general.Connection Value Installation Machine")}</span>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <MDBox mb={2}>
                                                    <FormControl mb={2} sx={{ width: '100%' }} variant="outlined">
                                                        <InputLabel htmlFor="outlined-adornment-connection" style={{ fontSize: mobileView ? '13px' : '' }} >{t("general.Connection Value")}</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-connection"
                                                            type='text'
                                                            endAdornment={
                                                                <InputAdornment position="end">Amp
                                                                </InputAdornment>
                                                            }
                                                            label={t("general.Connection Value")}
                                                            name="Add.connectionValue"
                                                            value={formik.values.Add.connectionValue}
                                                            onBlur={formik.handleBlur}
                                                            onChange={formik.handleChange}
                                                            fullWidth
                                                        />
                                                        {formik.errors.Add?.connectionValue && formik.touched.Add?.connectionValue && (
                                                            <Alert severity="error"><small>{t(`validation.${formik.errors.Add.connectionValue}`)}</small></Alert>
                                                        )}
                                                    </FormControl>
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <span style={{ fontSize: '15px' }}>{t("general.Public")}</span>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <MDBox mb={2}>
                                                    <Switch name="Add.public"
                                                        value={formik.values.Add.public}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange} />
                                                    {formik.errors.Add?.public && formik.touched.Add?.public && (
                                                        <Alert severity="error"><small>{t(`validation.${formik.errors.Add.public}`)}</small></Alert>
                                                    )}
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <span style={{ fontSize: '15px' }}>{t("general.Home Charger")}</span>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <MDBox mb={2}>
                                                    <Switch name="Add.homeCharger"
                                                        value={formik.values.Add.homeCharger}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange} />
                                                    {formik.errors.Add?.homeCharger && formik.touched.Add?.homeCharger && (
                                                        <Alert severity="error"><small>{t(`validation.${formik.errors.Add.homeCharger}`)}</small></Alert>
                                                    )}
                                                </MDBox>
                                            </Grid>
                                            {/* ----------------------------------------------------------- */}
                                            <Grid item xs={12}>
                                                <MDTypography variant="h5" mb={2}>
                                                    {t("general.Photos")}
                                                </MDTypography>
                                            </Grid>

                                            {/* -------------------------------CIRCUIT BREAKER--------------------------- */}
                                            <Grid container mb={2}>
                                                <Grid item xs={12}>
                                                    <span style={{ fontSize: '15px' }}>{t("general.Photo installation circuit breaker")} / {t("general.earth leakage circuit breaker")}</span>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={mobileView ? 12 : 4}
                                                    style={{ display: "flex", gap: "1rem", alignItems: "flex-start" }}
                                                >
                                                    <MDButton variant="gradient" color="info" type="button" onClick={handleFileStackCircuitBreaker}>
                                                        {t('action.Browse')}
                                                    </MDButton>
                                                    {circuitBreakerUrl ?
                                                        <a href={circuitBreakerUrl} target="_blank" rel="noreferrer">
                                                            <MDBox
                                                                name="Add.photoCircuitBreaker"
                                                                value={formik.values.Add.photoCircuitBreaker}
                                                                component="img"
                                                                src={circuitBreakerUrl}
                                                                alt={formik.values.Add.photoCircuitBreaker}
                                                                borderRadius="md"
                                                                shadow="md"
                                                                width="100%"
                                                                height="100%"
                                                                position="relative"
                                                                zIndex={1}
                                                                title="Click To Open"
                                                            />
                                                        </a> : <>  {formik.errors.Add?.photoCircuitBreaker && formik.touched.Add?.photoCircuitBreaker && (
                                                            <Alert severity="error"><small>{t(`validation.${formik.errors.Add.photoCircuitBreaker}`)}</small></Alert>
                                                        )}</>
                                                    }
                                                </Grid>
                                                <Grid item xs={1} />
                                            </Grid>
                                            {uploadCircuitBreaker &&
                                                <PickerOverlay
                                                    apikey={FILESTACK_KEY}
                                                    pickerOptions={{
                                                        onClose: () => {
                                                            // update your state
                                                            setCircuitBreaker(false);
                                                        }
                                                    }}
                                                    onUploadDone={(res) => handlephotoCircuitBreakerUpload(res)}
                                                />
                                            }
                                            {/* -------------------------------GROUP CABINET--------------------------- */}
                                            <Grid container mb={2}>
                                                <Grid item xs={12}>
                                                    <span style={{ fontSize: '15px' }}>{t("general.Overview photo group cabinet")}</span>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={mobileView ? 12 : 4}
                                                    style={{ display: "flex", gap: "1rem", alignItems: "flex-start" }}
                                                >
                                                    <MDButton variant="gradient" color="info" type="button" onClick={handleFileStackGroupBox}>
                                                        {t('action.Browse')}
                                                    </MDButton>
                                                    {/* {formik.initialValues.Add.photoGroupBox && < MDButton variant="gradient" color="primary" onClick={() => Navigate('/create/customer')}>
                                                        Remove
                                                    </MDButton>} */}
                                                    {groupBoxUrl ?
                                                        <a href={groupBoxUrl} target="_blank" rel="noreferrer">
                                                            <MDBox
                                                                component="img"
                                                                src={groupBoxUrl}
                                                                alt={groupBoxUrl}
                                                                borderRadius="md"
                                                                shadow="md"
                                                                width="100%"
                                                                height="100%"
                                                                position="relative"
                                                                zIndex={1}
                                                                title="Click To Open"
                                                            />
                                                        </a> : <>
                                                            {formik.errors.Add?.photoGroupBox && formik.touched.Add?.photoGroupBox && (
                                                                <Alert severity="error"><small>{t(`validation.${formik.errors.Add.photoGroupBox}`)}</small></Alert>
                                                            )}
                                                        </>
                                                    }
                                                </Grid>
                                                <Grid item xs={1} />
                                            </Grid>
                                            {uploadGroupBox &&
                                                <PickerOverlay
                                                    apikey={FILESTACK_KEY}
                                                    pickerOptions={{
                                                        onClose: () => {
                                                            // update your state
                                                            setGroupBox(false);
                                                        }
                                                    }}
                                                    onUploadDone={(res) => handlePhotoGroupBoxUpload(res)}
                                                />
                                            }
                                            {/* -------------------------------CABLE CONNECTED--------------------------- */}
                                            <Grid container mb={2}>
                                                <Grid item xs={12}>
                                                    <span style={{ fontSize: '15px' }}>{t("general.Photo of the cable connected to the charging unit")}</span>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={mobileView ? 12 : 4}
                                                    style={{ display: "flex", gap: "1rem", alignItems: "flex-start" }}
                                                >
                                                    <MDButton variant="gradient" color="info" type="button" onClick={handleFileStackCableCharging}>
                                                        {t('action.Browse')}
                                                    </MDButton>
                                                    {/* {formik.initialValues.Add.photoCableChargingDevice && < MDButton variant="gradient" color="primary" onClick={() => Navigate('/create/customer')}>
                                                        Remove
                                                    </MDButton>} */}
                                                    {cableChargingUrl ?
                                                        <a href={cableChargingUrl} target="_blank" rel="noreferrer">
                                                            <MDBox
                                                                component="img"
                                                                src={cableChargingUrl}
                                                                alt={cableChargingUrl}
                                                                borderRadius="md"
                                                                shadow="md"
                                                                width="100%"
                                                                height="100%"
                                                                position="relative"
                                                                zIndex={1}
                                                                title="Click To Open"
                                                            />
                                                        </a> : <>
                                                            {formik.errors.Add?.photoCableChargingDevice && formik.touched.Add?.photoCableChargingDevice && (
                                                                <Alert severity="error"><small>{t(`validation.${formik.errors.Add.photoCableChargingDevice}`)}</small></Alert>
                                                            )}
                                                        </>
                                                    }
                                                </Grid>
                                                <Grid item xs={1} />
                                            </Grid>
                                            {uploadCableCharging &&
                                                <PickerOverlay
                                                    apikey={FILESTACK_KEY}
                                                    pickerOptions={{
                                                        onClose: () => {
                                                            // update your state
                                                            setCableCharging(false);
                                                        }
                                                    }}
                                                    onUploadDone={(res) => handlePhotoCableChargingDeviceUpload(res)}
                                                />
                                            }

                                            {/* -------------------------------FRONT VEIW--------------------------- */}
                                            <Grid container mb={2}>
                                                <Grid item xs={12}>
                                                    <span style={{ fontSize: '15px' }}>{t("general.Front view loading unit")}</span>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={mobileView ? 12 : 4}
                                                    style={{ display: "flex", gap: "1rem", alignItems: "flex-start" }}
                                                >
                                                    <MDButton variant="gradient" color="info" type="button" onClick={handleFileStackFrontCharging}>
                                                        {t('action.Browse')}
                                                    </MDButton>
                                                    {/* {formik.initialValues.Add.photoFrontChargingDevice && < MDButton variant="gradient" color="primary" onClick={() => Navigate('/create/customer')}>
                                                        Remove
                                                    </MDButton>} */}
                                                    {frontChargingUrl ?
                                                        <a href={frontChargingUrl} target="_blank" rel="noreferrer">
                                                            <MDBox
                                                                component="img"
                                                                src={frontChargingUrl}
                                                                alt={frontChargingUrl}
                                                                borderRadius="md"
                                                                shadow="md"
                                                                width="100%"
                                                                height="100%"
                                                                position="relative"
                                                                zIndex={1}
                                                                title="Click To Open"
                                                            />
                                                        </a> : <>
                                                            {formik.errors.Add?.photoFrontChargingDevice && formik.touched.Add?.photoFrontChargingDevice && (
                                                                <Alert severity="error"><small>{t(`validation.${formik.errors.Add.photoFrontChargingDevice}`)}</small></Alert>
                                                            )}
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={1} />
                                            {uploadFrontCharging &&
                                                <PickerOverlay
                                                    apikey={FILESTACK_KEY}
                                                    pickerOptions={{
                                                        onClose: () => {
                                                            // update your state
                                                            setFrontCharging(false);
                                                        }
                                                    }}
                                                    onUploadDone={(res) => handlePhotoFrontChargingDeviceUpload(res)}
                                                />
                                            }

                                            {/* -------------------------------LABEL CAHRGING--------------------------- */}
                                            <Grid container mb={2}>
                                                <Grid item xs={12}>
                                                    <span style={{ fontSize: '15px' }}>{t("general.Label Charging")}</span>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={mobileView ? 12 : 4}
                                                    style={{ display: "flex", gap: "1rem", alignItems: "flex-start" }}
                                                >
                                                    <MDButton variant="gradient" color="info" type="button" onClick={handleFileStackLabelCharging}>
                                                        {t('action.Browse')}
                                                    </MDButton>
                                                    {/* {formik.initialValues.Add.photoLabelChargingDevice && < MDButton variant="gradient" color="primary" onClick={() => Navigate('/create/customer')}>
                                                        Remove
                                                    </MDButton>} */}
                                                    {labelChargingUrl ?
                                                        <a href={labelChargingUrl} target="_blank" rel="noreferrer">
                                                            <MDBox
                                                                component="img"
                                                                src={labelChargingUrl}
                                                                alt={labelChargingUrl}
                                                                borderRadius="md"
                                                                shadow="md"
                                                                width="100%"
                                                                height="100%"
                                                                position="relative"
                                                                zIndex={1}
                                                                title="Click To Open"
                                                            />
                                                        </a> : <>
                                                            {formik.errors.Add?.photoLabelChargingDevice && formik.touched.Add?.photoLabelChargingDevice && (
                                                                <Alert severity="error"><small>{t(`validation.${formik.errors.Add.photoLabelChargingDevice}`)}</small></Alert>
                                                            )}
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={1} />
                                            {uploadLabelCharging &&
                                                <PickerOverlay
                                                    apikey={FILESTACK_KEY}
                                                    pickerOptions={{
                                                        onClose: () => {
                                                            // update your state
                                                            setLabelCharging(false);
                                                        }
                                                    }}
                                                    onUploadDone={(res) => handlePhotoLabelChargingDeviceUpload(res)}
                                                />
                                            }
                                        </Grid>
                                        <MDBox mt={4} mb={1}>
                                            <MDButton variant="gradient" color="info" disabled={loader && true} type="submit">
                                                {t('action.Add')}
                                            </MDButton>
                                            <span> </span>
                                            <MDButton variant="gradient" color="primary" onClick={() => Navigate('/customers')}>
                                                {t('action.Cancel')}
                                            </MDButton>
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox >
            {/* <Footer /> */}
        </DashboardLayout >
    );
}

export default AddCustomer;
