// @mui material components
import { CircularProgress } from "@mui/material";
import Card from "@mui/material/Card";
import 'react-toastify/dist/ReactToastify.css';

function Loading() {
    return (
        <Card style={{
            textAlign: "center", margin: "auto",
            width: "60%",
            // border: "3px solid #73AD21", 
            padding: '10px',
            marginTop: '20%'
        }}>
            <CircularProgress color="secondary" />
            LOADING...
        </Card>
    );
}

export default Loading;
