import Moment from 'moment';
import { saveAs } from 'file-saver';
const Helpers = {
    // Format : 2023-09-04 04:09
    formatDate: function (date, format) {
        if (date) {
            const formatDate = Moment(date).format(format ? format : "YYYY-MM-DD HH:mm");
            return formatDate;
        }
    },
    // Format : 1 Hour 20 minutes
    dateDifference: function (StartDate, EndDate) {
        const stime = Moment(StartDate);
        const etime = Moment(EndDate);
        // get the difference between the Moments
        const diff = etime.diff(stime);
        //express as a duration
        const diffDuration = Moment.duration(diff);
        return `${diffDuration.hours()} Hour ${diffDuration.minutes()} minutes`;
    },
    capitalizeFirstLetter: function (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    // download CSV
    csvDownload: function (fileContent, header) {
        let fileName = 'file';
        const contentDisposition = header.get('Content-Disposition');
        if (contentDisposition) {
            const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = fileNameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, '');
            }
        }
        var blob = new Blob([fileContent], { type: "text/csv;charset=utf-8" });
        saveAs(blob, fileName);
        return;
    },
    downloadFile: function (url, fileName) {
        if (url && fileName) {
            saveAs(url, fileName);
        }
        return;
    }
}
export default Helpers;
